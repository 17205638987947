<template>
    <form class="form" novalidate="novalidate" id="st_login_form">
        <div class="form-group">
            <label> {{ $t("AUTH.INPUT.EMAIL") }} *</label>
            <div id="email-input-group" label="" label-for="email-input-group">
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                />
            </div>
        </div>
        <div class="form-group last">
            <div class="d-flex justify-content-between mt-n5">
                <label class="pt-5">{{ $t("AUTH.INPUT.PASSWORD") }} *</label>
                <router-link
                    v-if="!isPopUpEnabled"
                    :to="{ path: '/auth/forgot-password' }"
                    class="text-primary font-size-sm font-weight-bold text-hover-primary pt-5"
                >
                    {{ $t("AUTH.LOGIN.FORGOT_PASSWORD_LABEL") }}
                </router-link>
                <a v-if="isPopUpEnabled"
                    href="/auth/forgot-password"
                    class="text-primary font-size-sm font-weight-bold text-hover-primary pt-5"
                    >{{ $t("AUTH.LOGIN.FORGOT_PASSWORD_LABEL") }}</a
                >
            </div>
            <div
                id="password-input-group"
                label=""
                label-for="password-input-group"
            >
                <input
                    class="form-control form-control-solid h-auto py-4 px-6 rounded-sm"
                    type="password"
                    name="password"
                    ref="password"
                    autocomplete="off"
                    v-model="form.password"
                />
            </div>
        </div>
        <div class="pb-lg-0 mb-5">
            <button
                id="st_submit_button"
                ref="st_submit_button"
                class="btn btn-primary font-size-h4 w-100"
            >
                {{ $t("AUTH.LOGIN.BUTTON") }}
            </button>
        </div>
        <div v-if="isRegisterEnabled" class="pb-lg-0 mb-5">
            <router-link :to="{ path: '/auth/signup' }">
                <button
                    type="button"
                    id="st_cancel_button"
                    class="btn btn-outline-primary font-size-h4 w-100"
                >
                    {{ $t("AUTH.LOGIN.NO_ACCOUNT") }}
                </button>
            </router-link>
        </div>
        <p v-if="isRegisterEnabled" class="auth-help-text">{{ $t("AUTH.LOGIN.HELP_TEXT") }}</p>
    </form>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";

import { mapActions, mapGetters } from "vuex";

import { LOGIN, LOGOUT, GET_PROFILE } from "@/modules/auth/auth-store";

export default {
    props: {
        isRegisterEnabled: {
            type: Boolean,
            default: false,
        },
        isPopUpEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            form: {
                email: "",
                password: "",
            },
        };
    },
    computed: {
        ...mapGetters(["auth/currentUser"]),
    },
    methods: {
        ...mapActions({
            login: `auth/${LOGIN}`,
            logout: `auth/${LOGOUT}`,
            getProfile: `auth/${GET_PROFILE}`,
        }),
        showConfirmationModal(error) {
            const email = this?.form?.email;
            this.$alert({
                hasConfirmation: true,
                type: "warning",
                text: this.$t(`ERRORS.${error.message}`),
                confirmCallback: () => {
                    this.$router.push({
                        name: "changePassword",
                        params: { email },
                    });
                },
            });
        },
        getMessage(error) {
            if (!error?.data) return this.$t(`ERRORS.${error.message}`);
            if (error.data?.remaining_attempts) {
                return this.$t(`ERRORS.${error.message}`, [
                    error.data.remaining_attempts,
                ]);
            }
            return this.$t(`ERRORS.${error.message}`);
        },
    },
    mounted() {
        if (!this.isPopUpEnabled) {
            this.logout();
        }

        const signin_form = KTUtil.getById("st_login_form");

        this.fv = formValidation(signin_form, {
            fields: {
                email: {
                    validators: {
                        notEmpty: {
                            message: this.$t("AUTH.VALIDATION.REQUIRED"),
                        },
                        emailAddress: {
                            message: this.$t("AUTH.VALIDATION.EMAIL"),
                        },
                    },
                },
                password: {
                    validators: {
                        notEmpty: {
                            message: this.$t("AUTH.VALIDATION.REQUIRED"),
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        this.fv.on("core.form.valid", () => {
            const email = this.form.email;
            const password = this.form.password;

            // set spinner to submit button
            const submitButton = this.$refs["st_submit_button"];
            submitButton.classList.add(
                "spinner",
                "spinner-light",
                "spinner-right"
            );

            this.login({
                email: email,
                password: password,
            })
                .then((data) => {
                    const { isEmailConfirmed, isPhoneConfirmed } = data;
                    if (!isEmailConfirmed) {
                        this.$router.push({ name: "verifyEmail" });
                    } else if (!isPhoneConfirmed) {
                        this.$router.push({ name: "verifySMS" });
                    } else {
                        this.getProfile().then(() => {
                            if (this.isPopUpEnabled) {
                                this.$emit('hideModal', { route: "home" });
                            } else {
                                this.$router.push({ name: "dashboard" });
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (error.message === "AUTH_FAILED_PASSWORD_EXPIRED") {
                        this.showConfirmationModal(error);
                        return;
                    }
                    const message = this.getMessage(error);
                    this.$notify({
                        type: "error",
                        message,
                    });
                });

            submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        });
    },
};
</script>
