<template>
    <div class="text-truncate " ref='st-custom-cell-content' v-b-hover="hoverHandler">
        <span v-if="isHover && isTruncate">
            <span
                v-b-popover.hover="presenter"
                variant="outline-success"
            >
                {{presenter}}
            </span>
        </span>
        <span v-else>
            {{presenter}}
        </span>
    </div>
</template>

<script>
import { VBHover } from 'bootstrap-vue';

export default {
    name: 'DataTableCustomField',
    directives: {
        'b-hover': VBHover,
    },
    props: {
        presenter: {
            type: [String, Boolean],
        },
    },
    data() {
        return {
            isTruncate: false,
            isHover: false
        };
    },
    methods: {
       hoverHandler(isHovered) {
        if (isHovered) {
            const isTruncate = this.$refs['st-custom-cell-content'].offsetWidth < this.$refs['st-custom-cell-content'].scrollWidth;
            if(isTruncate) {
                this.isTruncate = true;
            }
            this.isHover = true;
        } else {
            this.isHover = false;
            this.isTruncate = false;
        }
      }
    },
};
</script>
