import i18n from '@/shared/plugins/vue-i18n';
import Notify from '@/shared/utils/notify';

const DEFAULT_ERROR_MESSAGE = 'ERRORS.DEFAULT_ERROR_MESSAGE';

export default class Message {
    static success(payload) {
        const message = i18n.t(payload);
        Notify('success', message);
    }

    static error(payload, params = {}) {
        const message = i18n.t(payload || DEFAULT_ERROR_MESSAGE, params);
        Notify('error', message);
    }

    static warning(payload) {
        const message = i18n.t(payload);
        Notify('warning', message);
    }

    static info(payload) {
        const message = i18n.t(payload);
        Notify('info', message);
    }
}
