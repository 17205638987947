<template>
    <div>
        <span>{{mainList}}</span>
        <span v-if='extraList'>
            +
            <b-button
                variant="link"
                class="px-0 text-decoration-none"
                v-b-popover.hover.blur="popoverConfig"
            >
                {{extraList}}
            </b-button>
        </span>
    </div>
</template>

<script>

export default {
    name: 'ApplicationTableCustomField',
    props: {
        items : {
            type: Array,
            default: () => []
        },
        fieldType : {
            type: String,
        },
    },
    computed: {
       extraList() {
            if(this.items && this.items.length > 2) {
                return this.items.length - 2;
            }
            return '';
        },
        popoverConfig() {
            return {
                html: true,
                title: () => {
                    // TODO: Remove in case title is not needed
                    return '';
                },
                content: () => {
                    let html = '';
                    let remainingList = [];
                    if (this.items && this.fieldType === 'beneficiary')  {
                        remainingList = this.items
                        .map(user => (`${user.first_name} ${user.last_name}`))
                        .slice(2);
                    }
                    if (this.items && this.fieldType === 'locality')  {
                        remainingList = this.items
                        .map(item => (item.territorial_administrative_unit))
                        .slice(2);
                    }

                    html += '<ul class="application-list-tooltip">';
                    remainingList.forEach((item) => {
                        html+= `<li>${item}</li>`;
                    })
                    html += '</ul>';

                return html;

                }
            }
        },
        mainList() {
            let items = []
            if (this.items && this.fieldType === 'beneficiary') {
                items = this.items
                ?.map(item => (`${item.first_name} ${item.last_name}`))
                .slice(0, 2)
                .join(', ');
            }
            if (this.items &&this.fieldType === 'locality') {
                items = this.items
                ?.map(item => (`${item.territorial_administrative_unit}`))
                .slice(0, 2)
                .join(', ');
            }
            return items;
        }
    },
};
</script>
<style>
.application-list-tooltip {
        list-style-type: none;
        padding: 0;
        margin: 0;
}
</style>
