import Vue from 'vue';
import Swal from 'sweetalert2';
import i18n from '@/shared/plugins/vue-i18n';

const TYPES = [
    'error',
    'warning',
    'success',
    'info'
];

const ICONS = {
    error: 'fas fa-exclamation-triangle',
    warning: 'fas fa-exclamation-circle',
    success: 'far fa-check-circle',
    info: 'fas fa-info-circle'
};

const alert = function(
    {
        type = 'error',
        title = '',
        text = '',
        isTextHtml = false,
        confirmButtonText,
        cancelButtonText,
        showCancelButton = false,
        showConfirmButton = true,
        showCloseButton = true,
        callback = null,
        hasConfirmation = false,
        confirmCallback = null,
        dismissCallback = null,
    }) {
        const validType = TYPES.includes(type) ? type : 'error';
        const alertTitle = title || i18n.t(`ALERT_MODAL.${validType.toUpperCase()}`);
        const iconClass = ICONS[type];
        const singleButton = !hasConfirmation && !(showConfirmButton && showCancelButton);
        const actionsClass = singleButton ? 'd-flex justify-content-center align-center' : 'd-flex';
        const defaultConfirmButtonText = i18n.t('GENERAL.BUTTON.CONFIRM');
        const defaultCancelButtonText = i18n.t('GENERAL.BUTTON.CANCEL');
        Swal.fire({
            title: alertTitle,
            text: !isTextHtml ? text : '',
            html: isTextHtml ? text: '',
            icon: validType,
            iconHtml: `<i class="${iconClass}"></i>`,
            showCancelButton: hasConfirmation || showCancelButton,
            showConfirmButton: hasConfirmation || showConfirmButton,
            confirmButtonColor: 'primary',
            cancelButtonColor: 'secondary',
            confirmButtonText: confirmButtonText || defaultConfirmButtonText,
            cancelButtonText: cancelButtonText || defaultCancelButtonText,
            showCloseButton: showCloseButton,
            closeButtonHtml: '<i class="fas fa-times"></i>',
            buttonsStyling: false,
            customClass: {
                container: 'st-alert-container',
                actions: actionsClass,
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-link'
            },
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed && confirmCallback) {
                confirmCallback();
                return;
            }
            if (result.dismiss === Swal.DismissReason.cancel && dismissCallback) {
                dismissCallback();
                Swal.close();
                return;
            }
            if (callback) {
                callback();
                return;
            }
        });
    };

Vue.prototype.$alert = alert;
Vue.alert = alert;
