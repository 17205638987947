import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const ResponseTemplatesList = () => import('@/modules/response-templates/pages/ResponseTemplatesList.vue');

const getResponseTemplatesModule = () => import('@/modules/response-templates/response-templates-store');
const importResponseTemplatesStore = () =>
    getResponseTemplatesModule().then((storeModule) => {
        if (!storeAsync().hasModule('responseTemplates')) storeAsync().registerModule('responseTemplates', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'response-templates',
                path: '/response-templates',
                component: ResponseTemplatesList,
                meta: {
                    auth: true,
                    permission: Permissions.values.responseTemplatesRead,
                },
                beforeEnter: (to, from, next) => {
                    importResponseTemplatesStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
