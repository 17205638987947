import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const FormsList = () => import('@/modules/forms/pages/FormsList.vue');
const FormBuilderForm = () => import('@/modules/forms/pages/FormBuilderForm.vue');

const getFormsModule = () => import('@/modules/forms/forms-store');
const importFormsStore = () =>
    getFormsModule().then((storeModule) => {
        if (!storeAsync().hasModule('forms')) storeAsync().registerModule('forms', storeModule.default);
        else return;
    });

const getApplicationsModule = () => import('@/modules/applications/applications-store');
const importApplicationsStore = () =>
    getApplicationsModule().then(async (storeModule) => {
        if (!storeAsync().hasModule('applications')) await storeAsync().registerModule('applications', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'forms',
                path: '/forms',
                component: FormsList,
                meta: {
                    auth: true,
                    permission: Permissions.values.formsRead,
                },
                beforeEnter: (to, from, next) => {
                    importFormsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'formNew',
                path: '/form/new',
                component: FormBuilderForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.formsCreate,
                },
                beforeEnter: (to, from, next) => {
                    importFormsStore().then(() => {
                        importApplicationsStore().then(() => {
                            next();
                        })
                    });
                },
            },
            {
                name: 'formView',
                path: '/form/:id',
                component: FormBuilderForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.formsRead,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importFormsStore().then(() => {
                        importApplicationsStore().then(() => {
                            next();
                        })
                    });
                },
            },
        ],
    },
];
