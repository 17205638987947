import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const Dashboard = () => import('@/modules/dashboard/pages/Dashboard.vue');
const DashboardReports = () => import('@/modules/dashboard/pages/DashboardReports.vue');

const getApplicationsModule = () => import('@/modules/applications/applications-store');
const getDashboardModule = () => import('@/modules/dashboard/dashboard-store');

const importApplicationsStore = () =>
    getApplicationsModule().then(async (storeModule) => {
        if (!storeAsync().hasModule('applications')) await storeAsync().registerModule('applications', storeModule.default);
        else return;
    });

const importDashboardStore = () =>
    getDashboardModule().then(async (storeModule) => {
        if (!storeAsync().hasModule('dashboard')) await storeAsync().registerModule('dashboard', storeModule.default);
        else return;
    });

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: {auth: true },
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: Dashboard,
        exact: true,
        meta: {
          auth: true,
          permission: Permissions.values.dashboardView,
        },
        beforeEnter: (to, from, next) => {
          importDashboardStore().then(() => {
            importApplicationsStore().then(() => {
              next();
            });
          });
        },
      },
      {
        name: 'dashboardReports',
        path: '/dashboard-reports',
        component: DashboardReports,
        exact: true,
        meta: {
            auth: true,
            permission: Permissions.values.reportsView
        },
        beforeEnter: (to, from, next) => {
          importDashboardStore().then(() => {
            importApplicationsStore().then(() => {
              next();
            });
          });
        },
     }
    ]
  }
];
