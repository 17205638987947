const Counties = [
  {
    "id": "1",
    "county": "Alba"
  },
  {
    "id": "2",
    "county": "Arad"
  },
  {
    "id": "3",
    "county": "Argeș"
  },
  {
    "id": "4",
    "county": "Bacău"
  },
  {
    "id": "5",
    "county": "Bihor"
  },
  {
    "id": "6",
    "county": "Bistrița-Năsăud"
  },
  {
    "id": "7",
    "county": "Botoșani"
  },
  {
    "id": "9",
    "county": "Brăila"
  },
  {
    "id": "8",
    "county": "Brașov"
  },
  {
    "id": "40",
    "county": "București"
  },
  {
    "id": "10",
    "county": "Buzău"
  },
  {
    "id": "51",
    "county": "Călărași"
  },
  {
    "id": "11",
    "county": "Caraș-Severin"
  },
  {
    "id": "12",
    "county": "Cluj",
    "uat_id": "127"
  },
  {
    "id": "13",
    "county": "Constanța"
  },
  {
    "id": "14",
    "county": "Covasna"
  },
  {
    "id": "15",
    "county": "Dâmbovița"
  },
  {
    "id": "16",
    "county": "Dolj"
  },
  {
    "id": "17",
    "county": "Galați"
  },
  {
    "id": "52",
    "county": "Giurgiu"
  },
  {
    "id": "18",
    "county": "Gorj"
  },
  {
    "id": "19",
    "county": "Harghita"
  },
  {
    "id": "20",
    "county": "Hunedoara"
  },
  {
    "id": "21",
    "county": "Ialomița"
  },
  {
    "id": "22",
    "county": "Iași"
  },
  {
    "id": "23",
    "county": "Ilfov"
  },
  {
    "id": "24",
    "county": "Maramureș"
  },
  {
    "id": "25",
    "county": "Mehedinți"
  },
  {
    "id": "26",
    "county": "Mureș"
  },
  {
    "id": "27",
    "county": "Neamț"
  },
  {
    "id": "28",
    "county": "Olt"
  },
  {
    "id": "29",
    "county": "Prahova"
  },
  {
    "id": "31",
    "county": "Sălaj"
  },
  {
    "id": "30",
    "county": "Satu Mare"
  },
  {
    "id": "32",
    "county": "Sibiu"
  },
  {
    "id": "33",
    "county": "Suceava"
  },
  {
    "id": "34",
    "county": "Teleorman"
  },
  {
    "id": "35",
    "county": "Timiș"
  },
  {
    "id": "36",
    "county": "Tulcea"
  },
  {
    "id": "38",
    "county": "Vâlcea"
  },
  {
    "id": "37",
    "county": "Vaslui"
  },
  {
    "id": "39",
    "county": "Vrancea"
  }
]

export { Counties as default };
