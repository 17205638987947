
import StAutocomplete from '@/shared/components/StAutocomplete';
import StModal from '@/shared/components/StModal';
import StInfoAlert from '@/shared/components/StInfoAlert';
import StDataTable from '@/shared/components/StDataTable';
import StSelect from '@/shared/components/StSelect';
import StDateRange from '@/shared/components/StDateRange';
import StButtonOnlyDatePicker from '@/shared/components/StButtonOnlyDatePicker';
import StDatepicker from '@/shared/components/StDatepicker';
import StFiltersPagination from '@/shared/components/filters-pagination/StFiltersPagination';
import StSection from '@/shared/components/StSection';
import StPage from '@/shared/components/StPage';
import StFile from '@/shared/components/StFile';
import StSearch from '@/shared/components/StSearch';

const GlobalComponents = {
  install(Vue) {
    Vue.component('StAutocomplete', StAutocomplete)
    Vue.component('StModal', StModal)
    Vue.component('StInfoAlert', StInfoAlert)
    Vue.component('StDataTable', StDataTable)
    Vue.component('StSelect', StSelect)
    Vue.component('StDateRange', StDateRange)
    Vue.component('StDatepicker', StDatepicker)
    Vue.component('StButtonOnlyDatePicker', StButtonOnlyDatePicker)
    Vue.component('StFiltersPagination', StFiltersPagination)
    Vue.component('StSection', StSection)
    Vue.component('StPage', StPage)
    Vue.component('StFile', StFile)
    Vue.component('StSearch', StSearch)
  }
}

export default GlobalComponents;
