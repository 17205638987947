import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const InternalDocumentsList = () => import('@/modules/internal-documents/pages/InternalDocumentsList.vue');
const InternalDocumentForm = () => import('@/modules/internal-documents/pages/InternalDocumentForm.vue');
const InternalDocumentsToSignList = () => import('@/modules/internal-documents/pages/InternalDocumentsToSignList.vue');

const getInternalDocumentsModule = () => import('@/modules/internal-documents/internal-documents-store');
const importInternalDocumentsStore = () =>
    getInternalDocumentsModule().then((storeModule) => {
        if (!storeAsync().hasModule('internalDocuments')) storeAsync().registerModule('internalDocuments', storeModule.default);
        else return;
    });
    
const getApplicationsModule = () => import('@/modules/applications/applications-store');
const importApplicationsStore = () =>
    getApplicationsModule().then((storeModule) => {
        if (!storeAsync().hasModule('applications')) storeAsync().registerModule('applications', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'internal-documents',
                path: '/internal-documents',
                component: InternalDocumentsList,
                meta: {
                    auth: true,
                    permission: Permissions.values.internalDocumentsRead,
                },
                beforeEnter: (to, from, next) => {
                    importInternalDocumentsStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'internalDocumentEdit',
                path: '/internal-document/:id',
                component: InternalDocumentForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.internalDocumentsCreate,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importInternalDocumentsStore().then(() => {
                        const store = storeAsync();
                        store.commit('shared/setModuleName','internalDocuments');
                        importApplicationsStore().then(() => {
                            next();
                        })
                    });
                },
            },
            {
                name: 'internalDocumentNew',
                path: '/internal-document-new',
                component: InternalDocumentForm,
                props: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.internalDocumentsCreate,
                },
                beforeEnter: (to, from, next) => {
                    importInternalDocumentsStore().then(() => {
                        const store = storeAsync();
                        store.commit('shared/setModuleName','internalDocuments');
                        importApplicationsStore().then(() => {
                            next();
                        })
                    });
                },
            },
            {
                name: 'sign-internal-documents',
                path: '/sign-internal-documents',
                component: InternalDocumentsToSignList,
                meta: {
                    auth: true,
                    permission: Permissions.values.internalDocumentsRead,
                },
                beforeEnter: (to, from, next) => {
                    importInternalDocumentsStore().then(() => {
                        next();
                    });
                },
            },
            
        ],
    },
];

