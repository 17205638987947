import Vue from 'vue';
import {
    BAvatar,
    BAvatarGroup,
    BButton,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BFormTextarea,
    BImg,
    BInputGroup,
    BLink,
    LayoutPlugin,
    TablePlugin,
    TabsPlugin,
    ModalPlugin,
    DropdownPlugin,
    CardPlugin,
    VBPopoverPlugin
} from 'bootstrap-vue';

Vue.use(VBPopoverPlugin);
Vue.use(DropdownPlugin);
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(TablePlugin);
Vue.use(TabsPlugin);
Vue.use(CardPlugin);
Vue.component('BAvatar', BAvatar);
Vue.component('BAvatarGroup', BAvatarGroup);
Vue.component('BButton', BButton);
Vue.component('BForm', BForm);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BFormCheckboxGroup', BFormCheckboxGroup);
Vue.component('BFormDatepicker', BFormDatepicker);
Vue.component('BFormFile', BFormFile);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BFormInput', BFormInput);
Vue.component('BFormRadio', BFormRadio);
Vue.component('BFormRadioGroup', BFormRadioGroup);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BFormSelectOption', BFormSelectOption);
Vue.component('BFormText', BFormText);
Vue.component('BFormTextarea', BFormTextarea);
Vue.component('BImg', BImg);
Vue.component('BInputGroup', BInputGroup);
Vue.component('BLink', BLink);
