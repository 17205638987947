<template lang="">
    <div class='floating-header'>
         <!--begin: Notifications -->
         <!-- TODO: uncomment once functionality is implemented -->
        <!-- <b-dropdown
            size="sm"
            variant="link"
            toggle-class="topbar-item text-decoration-none"
            no-caret
            right
            no-flip
        >
            <template v-slot:button-content>
                <div class="btn btn-icon">
                    <i class="fas fa-bell"></i>
                </div>
            </template>
            <b-dropdown-text tag="div" class="min-w-md-350px">
                <form>
                    <div>
                        <perfect-scrollbar
                            class="navi navi-hover scroll my-4"
                            style="max-height: 40vh; position: relative;"
                        >
                            <template v-for="(item, i) in notifications">
                                <a href="#" class="navi-item" v-bind:key="i">
                                    <div class="navi-link">
                                        <div class="navi-icon mr-2">
                                            <i v-bind:class="item.icon"></i>
                                        </div>
                                        <div class="navi-text">
                                            <div class="font-weight-bold">
                                                {{ item.title }}
                                            </div>
                                            <div class="text-muted">
                                                {{ item.desc }}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </template>
                        </perfect-scrollbar>
                    </div>
                </form>
            </b-dropdown-text>
        </b-dropdown> -->
        <!--end: Notifications -->
        <b-dropdown
            size="sm"
            variant="link"
            toggle-class="topbar-item text-decoration-none"
            no-caret
            right
            no-flip
        >
            <template v-slot:button-content>
                <b-avatar :src="defaultAvatar"></b-avatar>
            </template>
            <b-dropdown-text tag="div" class="min-w-md-350px">
                <form>
                    <div>
                        <!--begin::Content-->
                        <perfect-scrollbar
                            class="offcanvas-content pr-1 mr-n5 scroll"
                            style="max-height: 90vh; position: relative"
                        >
                            <!--begin::Header-->
                            <div class="d-flex align-items-center mt-5">
                                <div class="symbol symbol-100 mr-5">
                                    <span v-if="currentUser.photo">
                                        <img class="symbol-label" :src="currentUserPersonalInfo.photo" alt="" />
                                    </span>
                                    <span v-else>
                                        <img class="symbol-label" :src="defaultAvatar" alt="" />
                                    </span>
                                    <i class="symbol-badge bg-success"></i>
                                </div>
                                <div class="d-flex flex-column">
                                    <router-link
                                        to="/profile"
                                        class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                                    >
                                        {{ getFullName }}
                                    </router-link>
                                    <div class="mt-1">
                                        {{ userFunction }}
                                    </div>
                                    <div class="navi mt-2">
                                        <a href="#" class="navi-item">
                                            <span class="navi-link p-0 pb-2">
                                                <span class="navi-icon mr-1">
                                                    <i class="fas fa-envelope-open-text"></i>
                                                </span>
                                                <span class="navi-text text-hover-primary">
                                                    {{ currentUser.email }}
                                                </span>
                                            </span>
                                        </a>
                                    </div>
                                    <st-button
                                    id="btn_logout"
                                    :callback="onLogout">
                                        {{ $t('PROFILE.LOGOUT_BUTTON_TEXT') }}
                                    </st-button>
                                </div>
                            </div>
                            <!--end::Header-->
                            <div class="separator separator-dashed mt-8 mb-5"></div>
                            <!--begin::Nav-->
                            <div class="navi navi-spacer-x-0 p-0">
                                <router-link
                                    to="/profile"
                                    class="navi-item"
                                >
                                    <div class="navi-link">
                                        <div class="symbol symbol-40 bg-light mr-3">
                                            <div class="symbol-label">
                                                <i class="fas fa-user"></i>
                                            </div>
                                        </div>
                                        <div class="navi-text">
                                            <div class="st-dark-body font-weight-bolder">
                                                {{ $t(`PROFILE.OPTIONS.MY_PROFILE.NAME`) }}
                                            </div>
                                            <div>
                                                <span class="st-dark-caption">
                                                    {{ $t(`PROFILE.OPTIONS.MY_PROFILE.DESCRIPTION`) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                                <div
                                    class="navi-item"
                                    @click="onResetPassword"
                                >
                                    <div class="navi-link">
                                        <div class="symbol symbol-40 bg-light mr-3">
                                            <div class="symbol-label">
                                                <i class="fas fa-shield-alt"></i>
                                            </div>
                                        </div>
                                        <div class="navi-text">
                                            <div>
                                                <div class="st-dark-body font-weight-bolder">
                                                    {{ $t(`PROFILE.OPTIONS.RESET_PASSWORD.NAME`) }}
                                                </div>
                                            </div>
                                            <div>
                                                <span class="st-dark-caption">
                                                    {{ $t(`PROFILE.OPTIONS.RESET_PASSWORD.DESCRIPTION`) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <router-link
                                    v-can:read="'notificationsPreferences'"
                                    to="/notifications"
                                    class="navi-item"

                                >
                                    <div class="navi-link">
                                        <div class="symbol symbol-40 bg-light mr-3">
                                            <div class="symbol-label">
                                                <i class="fas fa-bell"></i>
                                            </div>
                                        </div>
                                        <div class="navi-text">
                                            <div class="st-dark-body font-weight-bolder">
                                                {{ $t(`PROFILE.OPTIONS.NOTIFICATIONS_MANAGEMENT.NAME`) }}
                                            </div>
                                            <div>
                                                <span class="st-dark-caption">
                                                    {{ $t(`PROFILE.OPTIONS.NOTIFICATIONS_MANAGEMENT.DESCRIPTION`) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <!--end::Nav-->
                        </perfect-scrollbar>
                        <!--end::Content-->
                    </div>
                </form>
            </b-dropdown-text>
        </b-dropdown>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { LOGOUT, SEND_PASSWORD_RESET_EMAIL } from '@/modules/auth/auth-store';

export default {
    name: 'FloatingHeader',
    components: {
    },
    data() {
        return {
            notifications: []
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            accountDetails: 'auth/accountDetails',
            defaultAvatar: 'shared/defaultAvatar',
        }),
        getFullName() {
            if (!this.currentUser?.first_name
                || !this.currentUser?.last_name) return '';
            return (
                this.currentUser.first_name +
                ' ' +
                this.currentUser.last_name
            );
        },
        userFunction() {
            // TODO: Temporary solution
            if (this.currentUser.user_type_name !== 'staff') return '';
            return this.accountDetails?.functions?.length ? this.accountDetails.functions[0] : '';
        }
    },
    methods: {
        ...mapActions({
            logout: `auth/${LOGOUT}`,
            sendPasswordResetEmail: `auth/${SEND_PASSWORD_RESET_EMAIL}`
        }),
        onLogout() {
            this.$alert({
                type: 'warning',
                text: this.$t('LOGOUT.CONFIRMATION_MESSAGE'),
                confirmButtonText: this.$t('GENERAL.YES'),
                cancelButtonText: this.$t('GENERAL.NO'),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.logout().then(() => this.$router.push({ name: 'signin' }));
                }
            });
        },
        onResetPassword() {
            this.$alert({
                type: 'warning',
                text: this.$t('RESET_PASSWORD.CONFIRMATION_MESSAGE'),
                confirmButtonText: this.$t('GENERAL.YES'),
                cancelButtonText: this.$t('GENERAL.NO'),
                hasConfirmation: true,
                confirmCallback: () => {
                    const { email } = this.currentUser;
                    this.sendPasswordResetEmail({ email }).then(() => {
                        this.$alert({
                            type: 'success',
                            text: this.$t('RESET_PASSWORD.SUCCESSFULLY_SENT_MESSAGE'),
                            cancelButtonText: this.$t('GENERAL.CLOSE'),
                            showCancelButton: true,
                            showConfirmButton: false,
                        });
                    });
                }
            });
        },
    },
}
</script>
<style lang="scss">
    .floating-header {
        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 1;
        background-color: #F2F3F7;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
        border-radius: 4px;
        display: flex;
    }
</style>
