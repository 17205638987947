<template>
    <div>
        <vue-select
            v-model="selected"
            :options="computedOptions"
            :label="textField"
            class="st-search form-control"
            :class="customClass"
            :reduce="el => el[valueField]"
            :clearable="clearable"
            :disabled="disabled"
            :placeholder="placeholder"
            :filterable="false"
            @search="searchOptions"
        >
            <template #no-options="{search, searching}">
                <template v-if="searching">
                    {{ $t('GENERAL.NO_RESULTS')}} <em>{{ search }}</em>.
                </template>
                <em v-else>{{ searchOptionText }}</em>
            </template>
            <template #option="option" disabled>
                <div v-if="option.description">
                    <span>{{ option.description }}</span>
                    <br>
                    <span><strong>{{ option[textField] }}</strong></span>
                    <div v-if="option.divider" class="border-bottom"></div>
                </div>
                <span v-else>{{ option[textField] }}</span>
            </template>
            <template v-if="listHeader" #list-header>
                <li class="text-center p-4">
                    {{ listHeader }}
                </li>
            </template>
        </vue-select>
        <input v-show="false" :name="name" type="text" v-model="selected">
    </div>
</template>

<script>
    import vSelect from 'vue-select';
    export default {
        inheritAttrs: false,
        name: 'StSearch',
        components: {
            'vue-select': vSelect,
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            options: {
                type: Array,
                default: () => [],
            },
            name: {
                type: String,
                required: true,
            },
            textField: {
                type: String,
                default: 'text',
            },
            valueField: {
                type: String,
                default: 'value',
            },
            customClass: String,
            disabled: {
                type: Boolean,
                default: false,
            },
            reference: String,
            clearable: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
            searchFnc: {
                type: Function,
            },
            searchText: {
                type: String,
                default: ''
            },
            debounce: {
                type: Number,
                default: 300
            },
            listHeader: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                field: this.fields?.[this.name],
                timeout: null,
                foundOptions: this.options,
            }
        },
        computed: {
            selected: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                    this.$emit('change', val);

                    this.$emit('selected', {
                        selected: val,
                        options: this.computedOptions,
                    });
                }
            },
            computedOptions: {
                get() {
                    return this.foundOptions;
                },
                set(value) {
                    this.foundOptions = value;
                }
            },
            searchOptionText() {
                return this.$t('GENERAL.SEARCH_OPTIONS', [this.searchText])
            },
        },
        methods: {
            searchOptions(value, loading) {
                if (value.length) {
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }

                    loading(true);

                    this.timeout = setTimeout(() => {
                        this.searchFnc(value).then((response) => {
                            this.computedOptions = response;
                            loading(false);
                        })
                    }, this.debounce);
                }
            }
        },
        watch: {
            options(newVal) {
                this.foundOptions = newVal;
            },
        },
    };
</script>
