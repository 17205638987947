import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const ResponsibilitiesList = () => import('@/modules/responsibilities/pages/ResponsibilitiesList.vue');

const getResponsabilitesModule = () => import('@/modules/responsibilities/responsibilities-store');
const importResponsabilitiesStore = () =>
    getResponsabilitesModule().then((storeModule) => {
        if (!storeAsync().hasModule('responsibilities')) storeAsync().registerModule('responsibilities', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'responsibilities',
                path: '/responsibilities',
                component: ResponsibilitiesList,
                meta: {
                    auth: true,
                    permission: Permissions.values.responsibilityRead,
                },
                beforeEnter: (to, from, next) => {
                    importResponsabilitiesStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
