<template>
    <!-- begin:: Header -->
    <div id="kt_header" ref="kt_header" class="header" v-bind:class="headerClasses">
        <div class="container-fluid d-flex align-items-center justify-content-between">
            <!-- begin:: Header Menu -->
            <div class="header-menu-wrapper header-menu-wrapper-left">
                <div
                    v-if="headerMenuEnabled"
                    id="kt_header_menu"
                    ref="kt_header_menu"
                    class="header-menu header-menu-mobile"
                    v-bind:class="headerMenuClasses"
                >
                    <!-- example static menu here -->
                    <HeaderMenu v-if="false"></HeaderMenu>
                </div>
            </div>
            <!-- end:: Header Menu -->
            <Topbar></Topbar>
        </div>
    </div>
    <!-- end:: Header -->
</template>

<script>
import { mapGetters } from 'vuex';
import Topbar from '@/modules/layout/components/header/Topbar.vue';
import HeaderMenu from '@/modules/layout/components/header/Menu.vue';
import StLayoutHeader from '@/assets/js/layout/base/header.js';
import StLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js';

export default {
    name: 'Header',
    components: {
        Topbar,
        HeaderMenu,
    },
     props: {
        isMaintenance: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        // Init Desktop & Mobile Headers
        StLayoutHeader.init('kt_header', 'kt_header_mobile');

        // Init Header Menu
        StLayoutHeaderMenu.init(this.$refs['kt_header_menu'], this.$refs['kt_header_menu_wrapper']);
    },
    computed: {
        ...mapGetters(['layoutConfig', 'getClasses']),

        /**
         * Check if the header menu is enabled
         * @returns {boolean}
         */
        headerMenuEnabled() {
            return !!this.layoutConfig('header.menu.self.display');
        },

        /**
         * Get extra classes for header based on the options
         * @returns {null|*}
         */
        headerClasses() {
            const classes = this.getClasses('header');
            if (typeof classes !== 'undefined') {
                if (this.isMaintenance) classes.push('header-custom')
                return classes.join(' ');
            }
            return null;
        },

        /**
         * Get extra classes for header menu based on the options
         * @returns {null|*}
         */
        headerMenuClasses() {
            const classes = this.getClasses('header_menu');
            if (typeof classes !== 'undefined') {
                return classes.join(' ');
            }
            return null;
        },
    },
};
</script>
