<template>
    <div>
        <st-modal
            id="login-modal"
            ref="login-modal"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
            :hideable="true"
            :title="this.$t('GENERAL.EXPIRED_SESSION')"
            @modalClosed="close"
        >
            <template #header> </template>

            <template #body>
                <div v-if="showSocialLogin">
                    <google-login-button :formType="formType"  />
                    <facebook-login-button
                        :formType="formType"
                        :isPopUpEnabled="true"
                        @hideModal="hideModal"
                    />
                    <div class="m-5 text-center">
                        {{ $t("AUTH.GENERAL.OR") }}
                    </div>
                </div>
                <login-form
                    @hideModal="hideModal"
                    :isRegisterEnabled="false"
                    :isPopUpEnabled="true"
                ></login-form>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoginForm from "@/modules/auth/components/LoginForm";
import FacebookLoginButton from "@/modules/auth/components/FacebookLoginButton";
import GoogleLoginButton from "@/modules/auth/components/GoogleLoginButton";
import { routerAsync } from '@/app-module';

export default {
    name: "LoginModal",
    components: {
        LoginForm,
        FacebookLoginButton,
        GoogleLoginButton,
    },
    props: {},
    data() {
        return {
            formType: "login",
        };
    },
    computed: {
        ...mapGetters({
            isTokenExpired: "auth/isTokenExpired",
        }),
        showSocialLogin() {
            const router = routerAsync();
            return !router.app.renderInApp;
        }
    },
    methods: {
        ...mapActions({
            logout: "auth/logout",
        }),
        hideModal(params) {
            this.$bvModal.hide("login-modal");
            if (params.route.name !== this.$route.name) {
                this.$router.push({ name: params.route });
                // reload page in order to refresh user permissions in case other user logs in after the session expired
                window.location.reload();
            }
        },
        close() {
            this.logout().then(() => {
                this.$router.go();
            }).catch(() => {
                this.$router.push({ name: 'signin' });
            });
        },
    },
};
</script>
