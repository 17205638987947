<template>
    <div>
        <Loader v-if="isLoading"/>
        <ul class="menu-nav custom-menu-nav" ref="atlasMenu">
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/atlas/layers">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                    @click="navigateTo('layers')"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-layer-group"/>
                        <span class="menu-text">{{$t('ATLAS.MENU.LAYER_MANAGER')}}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/atlas/applications">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                    @click="navigateTo('applications')"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-info-circle"/>
                        <span class="menu-text">{{$t('ATLAS.MENU.MY_APPLICATIONS')}}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="isAuthenticated"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/atlas/interest-areas">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                    @click="navigateTo('interest-areas')"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-draw-polygon"/>
                        <span class="menu-text">{{$t('ATLAS.MENU.INTEREST_ZONES')}}</span>
                    </a>
                </li>
            </router-link>
            <!-- parcel search section -->
            <li
                class="parcel-search-sidebar-container">
                <parcel-search-sidebar-container/>
            </li>
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                v-if="isSuperAdmin"
            >
                <a href="href" class="menu-link" @click.prevent="navigateTo('layers-visibility-order')">
                    <i class="menu-icon fas fa-list-ol"/>
                    <span class="menu-text">{{$t('ATLAS.MENU.VISIBILITY_MANAGER')}}</span>
                </a>
            </li>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/atlas/search">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-search"/>
                        <span class="menu-text">{{$t('ATLAS.MENU.SEARCH')}}</span>
                    </a>
                </li>
            </router-link>
            <li v-if="isStaff">
                <hr/>
            </li>
            <router-link
                v-if="isStaff"
                v-slot="{ href, isActive, isExactActive }"
                to="/atlas/help">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click.prevent="navigateTo('landing-page')">
                        <i class="menu-icon fas fa-question-circle"/>
                        <span class="menu-text">{{$t('ATLAS.MENU.HELP')}}</span>
                    </a>
                </li>
            </router-link>
            <li>
                <hr/>
            </li>
            <li class="menu-section">
                <h4 class="menu-text">{{ $t('MENU.EXTERNAL_APPS') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/archive-dashboard"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                     v-can:read="'archive'"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-map"/>
                        <span class="menu-text">{{ $t('MENU.ARCHIVE') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, isActive, isExactActive }"
                to="/dashboard"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click.prevent="goToHome">
                        <i class="menu-icon fas fa-archive"/>
                        <span class="menu-text">{{ $t('MENU.GU') }}</span>
                    </a>
                </li>
            </router-link>
            <li v-if="false">
                <hr/>
            </li>
            <!-- Help Section -->
            <li class="menu-section" v-if="false">
                <h4 class="menu-text">{{ $t('MENU.HELP') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/manual">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon flaticon2-open-text-book"/>
                        <span class="menu-text">{{ $t('MENU.MANUAL') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/faq">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-question-circle"/>
                        <span class="menu-text">{{ $t('MENU.FAQ') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/gdpr">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-book"/>

                        <span class="menu-text">GDPR</span>
                    </a>
                </li>
            </router-link>
        </ul>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'MenuAtlas',
        data() {
            return {}
        },
        components: {
            ParcelSearchSidebarContainer: () => import(/* webpackChunkName: "atlas" */ "@/modules/atlas/components/parcel-search/ParcelSearchSidebarContainer.vue"),
        },
        computed: {
            ...mapGetters({
                currentUser: 'auth/currentUser',
                isStaff: 'auth/isStaff',
                isAuthenticated: 'auth/isAuthenticated',
                isSuperAdmin: 'auth/isSuperAdmin',
                isAtlasPublicView: 'shared/isAtlasPublicView',
                isIntersectionManagerVisible: 'atlas/sidebar/isIntersectionManagerVisible',
                loading: 'shared/loading',
            }),
            isLoading () {
                return false;
            },
        },
        methods: {
            ...mapActions({
                showSidebar: 'atlas/sidebar/show',
                hideIntersectionManager: 'atlas/sidebar/hideIntersectionManager',
                showVisibilitySettings: 'atlas/sidebar/showVisibilitySettings',
            }),
            goToHome() {
                this.$emit('homePage');
            },
            navigateTo(item) {
                if (item === 'landing-page') {
                    window.open(`${window.VUE_APP_LANDING_PAGE_URL}/intrebari-frecvente`, '_blank');
                }
                if (item === 'layers-visibility-order') {
                    this.showVisibilitySettings(true);
                }
                if ((item === 'layers' || item === 'interest-areas') && this.isIntersectionManagerVisible) {
                    this.hideIntersectionManager()
                }

                this.showSidebar();
            }
        },
    };
</script>
