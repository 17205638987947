import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const SystemSettingsList = () => import('@/modules/system-settings/pages/SystemSettingsList.vue');

import { storeAsync } from '../../app-module';
const getSystemSettingsModule = () => import('@/modules/system-settings/system-settings-store');
const importSystemSettingsStore = () =>
    getSystemSettingsModule().then((storeModule) => {
        if (!storeAsync().hasModule('systemSettings')) storeAsync().registerModule('systemSettings', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'system-settings',
                path: '/system-settings',
                component: SystemSettingsList,
                meta: {
                    auth: true,
                    permission: Permissions.values.systemSettingsRead,
                },
                beforeEnter: (to, from, next) => {
                    importSystemSettingsStore().then(() => {
                        next();
                    });
                }
            },
        ],
    },
];
