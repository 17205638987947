<template>
    <div>
        <vue-select
            v-model="selected"
            :options="options"
            :label="textField"
            class="st-autocomplete form-control"
            :name="name"
            :class="customClass"
            :reduce="el => el[valueField]"
            :clearable="clearable"
            :disabled="disabled"
            :placeholder="placeholder"
            :filter="filterOptions"
            :multiple="multiple"
        >
            <template #no-options="{}">
                {{ $t('GENERAL.AUTOCOMPLETE_NO_OPTIONS') }}
            </template>
        </vue-select>
    </div>
</template>

<script>
    import vSelect from 'vue-select';
    export default {
        inheritAttrs: false,
        name: 'StAutocomplete',
        components: {
            'vue-select': vSelect,
        },
        props: {
            value: {
                type: [String, Array],
                default: ''
            },
            options: {
                type: Array,
                default: () => [],
            },
            name: {
                type: String,
                required: true,
            },
            textField: {
                type: String,
                default: 'text',
            },
            valueField: {
                type: [String, Array],
                default: 'value',
            },
            customClass: String,
            disabled: {
                type: Boolean,
                default: false,
            },
            reference: String,
            clearable: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: '',
            },
            fields: Object,
            model: Object,
            formValidation: Object,
            multiple: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                field: this.fields?.[this.name],
            }
        },
        watch: {
            options(newValue) {
                if (this.field?.autocompleteRequired) {
                    if (!this.field.enabledValidation) {
                        this.formValidation[newValue.length ? 'enableValidator' : 'disableValidator'](this.field.name);
                    }

                    this.formValidation.resetField(this.field.name, true);
                }
            },
            selected(newValue, oldValue) {
                if (newValue === oldValue) return false;
                if (newValue && this.field?.autocompleteRequired) {
                    this.formValidation.revalidateField(this.field.name, true);
                }
            },
        },
        computed: {
            selected: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                    this.$emit('change', val);
                }
            },
            hasParents() {
                return !!this.field?.parentFields.length;
            },
        },
        methods: {
            addWatchers() {
                const callbackFn = this.watcherCallbackFn;
                this.field.parentFields.forEach((el) => {
                    this.$watch(`model.${el.name}`, callbackFn, { deep: true });

                    this.field.fetchParams = { [el.key]: this.model[el.name] };
                    this.field.updateOptions();
                }, this);

            },
            watcherCallbackFn(newValue, oldValue) {
                if (newValue === oldValue) return false;
                this.model[this.field.name] = '';
                // this.field.fetchParams = { parent_id: newValue };
                // this.field.updateOptions();

                this.field.parentFields.forEach((el) => {
                    this.field.fetchParams = { [el.key]: newValue };
                    this.field.updateOptions();
                }, this);
            },
            /**
             * Filter options from start only
             * Usign unicode normalization form of the options text
             */
            filterOptions(options, search) {
                return options.filter((el) => {
                    const normalized = el[this.textField].toLowerCase().normalize('NFKD');
                    const normalizedOnlyWord = normalized.replace(/[^\w]/g, '');
                    const searchLowerCase = search.toLowerCase();
                    return normalized.includes(searchLowerCase)
                        || normalizedOnlyWord.includes(searchLowerCase);
                });
            },
        },
        async mounted() {
            if (this.field) {
                await this.field.updateOptions();
            }
            if (this.hasParents) {
                this.addWatchers();
            }
        },
        beforeDestroy() {
            if (this.field && this.field.fetchParams) {
                this.field.fetchParams = {};
            }
        },
    };
</script>
