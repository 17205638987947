const Layout = () => import('@/modules/layout/components/layout');
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const Beneficiaries = () => import('@/modules/beneficiaries/pages/Beneficiaries.vue')
const getBeneficiariesModule = () => import('@/modules/beneficiaries/beneficiaries-store');
const importBeneficiariesStore = () =>
getBeneficiariesModule().then((storeModule) => {
        if (!storeAsync().hasModule('beneficiaries')) {
            storeAsync().registerModule('beneficiaries', storeModule.default);
        }
        else return;
    });
export default [
    {
        path: '',
        exact: true,
        component: Layout,
        children: [
            {
                name: 'beneficiaries',
                path: '/beneficiaries',
                component: Beneficiaries,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.beneficiariesRead,
                },
                beforeEnter: (to, from, next) => {
                    importBeneficiariesStore().then(() => {
                        next();
                    });
                },
            },
        ],
    }
]
