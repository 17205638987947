import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const DecisionPurposeList = () => import('@/modules/decision-purpose/pages/DecisionPurposeList.vue');

import { storeAsync } from '../../app-module';
const getDecisionPurposeModule = () => import('@/modules/decision-purpose/decision-purpose-store');
const importDecisionPurposeStore = () =>
    getDecisionPurposeModule().then((storeModule) => {
        if (!storeAsync().hasModule('decisionPurpose')) storeAsync().registerModule('decisionPurpose', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'decision-purpose-list',
                path: '/decision-purpose-list',
                component: DecisionPurposeList,
                meta: {
                    auth: true,
                    permission: Permissions.values.decisionPurposeRead,
                },
                beforeEnter: (to, from, next) => {
                    importDecisionPurposeStore().then(() => {
                        next();
                    });
                }
            },
        ],
    },
];
