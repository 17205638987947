<template>
    <ApplicationTableCustomField
        fieldType="locality"
        :items="items"
    />
</template>

<script>
import ApplicationTableCustomField from '@/modules/applications/components/ApplicationTableCustomField';
export default {
    name: 'LocalityCustomField',
    components: { ApplicationTableCustomField },
    props: {
        dataObj: {
            type: Object,
        }
    },
    computed: {
        items() {
            return this.dataObj.item.form?.real_estate_target ?? [];
        }
    }
};
</script>
