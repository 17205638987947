import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { notificationTemplateResolver } from './resolvers/notification-template-resolver';

const Notifications = () => import('@/modules/notifications/pages/Notifications.vue');
const NotificationsTemplates = () => import('@/modules/notifications/pages/NotificationsTemplates.vue');
const EditNoticationTemplate = () => import('@/modules/notifications/components/EditNotificationTemplate.vue');

export default [
    {
        path: '',
        exact: true,
        component: Layout,
        children: [
            {
                name: 'notifications',
                path: '/notifications',
                component: Notifications,
                exact: true,
                meta: {
                    auth: true,
                    // TODO: uncomment once notifications permissions is done - BE side-
                    // permission: Permissions.values.notificationsPreferencesRead
                }
            },
            {
                name: 'notifications-templates',
                path: '/notifications-templates',
                component: NotificationsTemplates,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.notificationsTemplateRead
                }
            },
            {
                name: 'notifications-templates-edit',
                path: '/notifications-templates/:id/edit',
                component: EditNoticationTemplate,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.notificationsTemplateEdit
                },
                beforeEnter: notificationTemplateResolver
            }
        ]
    }
];
