import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class DashboardPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.viewOwn = permissionChecker.match(
            Permissions.values.dashboardViewOwn,
        );
        this.viewOrg =  permissionChecker.match(
            Permissions.values.dashboardViewOrg,
        );
        this.view =  permissionChecker.match(
            Permissions.values.dashboardView,
        );
        this.viewReports = permissionChecker.match(
            Permissions.values.reportsView,
        );
    }
}
