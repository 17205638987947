import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class InstitutionsPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.organisationsRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.organisationsCreate,
        );
        this.edit = permissionChecker.match(
            Permissions.values.organisationsEdit,
        );
        this.destroy = permissionChecker.match(
            Permissions.values.organisationsDestroy,
        );
        this.viewOrganisations = permissionChecker.match(
            Permissions.values.organisationsView,
        );
        // Functions
        this.viewFunctions = permissionChecker.match(
            Permissions.values.organisationsFunctionsView,
        );
        this.editFunctions = permissionChecker.match(
            Permissions.values.organisationsFunctionsEdit,
        );
        this.destroyFunctions = permissionChecker.match(
            Permissions.values.organisationsFunctionsDestroy,
        );
        this.viewFunctionsUsers = permissionChecker.match(
            Permissions.values.organisationsUsersFunctionsView,
        );
        // Departments
        this.viewDepartments = permissionChecker.match(
            Permissions.values.organisationsDepartmentsView,
        );
        this.createDepartments = permissionChecker.match(
            Permissions.values.organisationsDepartmentsCreate,
        );
        this.editDepartments = permissionChecker.match(
            Permissions.values.organisationsDepartmentsEdit,
        );
        this.viewDepartmentsFunctions = permissionChecker.match(
            Permissions.values.organisationsDepartmentsFunctionsView,
        );
        this.destroyDepartments = permissionChecker.match(
            Permissions.values.organisationsDepartmentsDelete,
        );
        // Users
        this.viewUsers = permissionChecker.match(
            Permissions.values.organisationsUsersView,
        );
        this.createStaffUsers = permissionChecker.match(
            Permissions.values.organisationsStaffUsersCreate,
        );
        this.editStaffUsers = permissionChecker.match(
            Permissions.values.organisationsStaffUsersEdit,
        );
        this.resetPasswordStaffUsers = permissionChecker.match(
            Permissions.values.organisationsStaffUsersResetPassword,
        );
        this.destroyStaffUsers = permissionChecker.match(
            Permissions.values.organisationsStaffUsersDestroy,
        );
        // Taxes
        this.viewTaxes = permissionChecker.match(
            Permissions.values.organisationsTaxesView,
        );
        this.editTaxes = permissionChecker.match(
            Permissions.values.organisationsTaxesEdit,
        );
        // Bank Accounts
        this.viewAllBankAccounts = permissionChecker.match(
            Permissions.values.organisationsBankAccountsView,
        );
        this.viewBankAccounts = permissionChecker.match(
            Permissions.values.organisationsBankAccountsRead,
        );
        this.createBankAccounts = permissionChecker.match(
            Permissions.values.organisationsBankAccountsCreate,
        );
        this.editBankAccounts = permissionChecker.match(
            Permissions.values.organisationsBankAccountsEdit,
        );
        this.destroyBankAccounts = permissionChecker.match(
            Permissions.values.organisationsBankAccountsDestroy,
        );
        // Responsibilities
        this.viewOrgResponsibilities = permissionChecker.match(
            Permissions.values.organisationsResponsibilitiesView,
        );
        this.editOrgResponsibilities = permissionChecker.match(
            Permissions.values.organisationsResponsibilitiesEdit,
        );
        this.destroyOrgResponsibilities = permissionChecker.match(
            Permissions.values.organisationsResponsibilitiesDestroy,
        );
        // DocumentSeries
        this.viewDocumentSeries = permissionChecker.match(
            Permissions.values.organisationsDocumentSeriesView,
        );
        this.editDocumentSeries = permissionChecker.match(
            Permissions.values.organisationsDocumentSeriesEdit,
        );
        // My Organisation
        this.viewOwnOrganisation = permissionChecker.match(
            Permissions.values.organisationsViewOwn,
        );
        // Decisions
        this.viewOrgDecisions = permissionChecker.match(
            Permissions.values.organisationsDecisionView,
        );
        this.editOrgDecisions = permissionChecker.match(
            Permissions.values.organisationsDecisionEdit,
        );
        this.destroyOrgDecisions = permissionChecker.match(
            Permissions.values.organisationsDecisionDestroy,
        );
        this.createOrgDecisions = permissionChecker.match(
            Permissions.values.organisationsDecisionCreate,
        );
        // Territorial Unit Allocation
        this.viewOrgTerritorialUnitAllocation = permissionChecker.match(
            Permissions.values.organisationsTerritorialUnitAllocationView,
        );
        this.editOrgTerritorialUnitAllocation = permissionChecker.match(
            Permissions.values.organisationsTerritorialUnitAllocationEdit,
        );
        this.destroyOrgTerritorialUnitAllocation = permissionChecker.match(
            Permissions.values.organisationsTerritorialUnitAllocationDestroy,
        );
        this.createOrgTerritorialUnitAllocation = permissionChecker.match(
            Permissions.values.organisationsTerritorialUnitAllocationCreate,
        );
    }
}
