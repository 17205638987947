<template>
    <div :class="['st-page', pageCustomClass]">
        <div :class="['st-page-header', { 'h-auto': duplicateToolbar }]" v-if="showHeader">
            <slot v-if="duplicateToolbar" name="toolbar"></slot>
            <template v-else>
                <div class="st-page-title">
                    <div v-if="showBackButton" @click="goBack" class="st-back-arrow">
                        <i class="fas fa-arrow-left"></i>
                    </div>
                    <div class="title-wrapper">
                        <div class="font-size-h5">{{ title }}</div>
                        <div v-if="hasSubtitle" class="st-page-subtitle">{{ subtitle }}</div>
                    </div>
                    <slot name="additional-title"></slot>
                </div>
                <div class="st-page-toolbar">
                    <slot name="toolbar"></slot>
                </div>
            </template>
        </div>
        <div :class="pageBodyClass">
            <slot></slot>
        </div>
        <div class="st-page-footer">
            <slot v-if="duplicateToolbar" name="toolbar"></slot>
            <slot v-else name="footer"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StPage',
        props: {
            title: {
                type: String,
                default: '',
            },
            subtitle: {
                type: String,
                default: '',
            },
            backLink: [String, Object],
            layout: {
                type: String,
                default: '', // tab, list, form
            },
            showHeader: {
                type: Boolean,
                default: true
            },
            hideBackButton: {
                type: Boolean,
                default: false,
            },
            customClass: String,
            pageCustomClass: String,
            duplicateToolbar: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            pageBodyClass() {
                let cssClass = this.duplicateToolbar ? 'd-flex' : 'st-page-body';
                if (this.customClass) {
                    cssClass += ` ${this.customClass}`;
                }
                if (this.layout === 'tab') {
                    cssClass += ' st-tab-layout mt-6';
                }
                if (this.layout === 'section') {
                    cssClass += ' st-section-layout'
                }
                return cssClass;
            },
            showBackButton() {
                if (!this.backLink) return false;
                if (this.backLink && this.hideBackButton) return false;
                return true;
            },
            hasSubtitle() {
                return this.subtitle;
            }
        },
        methods: {
            goBack() {
                this.$router.push(this.backLink);
            }
        }
    };
</script>
