import Layout from '@/modules/layout/components/layout';

const ProcessEditorList = () => import('@/modules/process-editor/pages/ProcessEditorList.vue');
const ProcessForm = () => import('@/modules/process-editor/pages/ProcessForm.vue');
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const getProcessEditorModule = () => import('@/modules/process-editor/process-editor-store');
const importProcessEditorStore = () =>
    getProcessEditorModule().then((storeModule) => {
        if (!storeAsync().hasModule('processEditor')) storeAsync().registerModule('processEditor', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'process-editor',
                path: '/process-editor',
                component: ProcessEditorList,
                meta: {
                    auth: true,
                    permission: Permissions.values.processEditorRead,
                },
                beforeEnter: (to, from, next) => {
                    importProcessEditorStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'processNew',
                path: '/process/new',
                component: ProcessForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.processEditorCreate,
                },
                beforeEnter: (to, from, next) => {
                    importProcessEditorStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'processView',
                path: '/process/:id',
                component: ProcessForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.processEditorEdit,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importProcessEditorStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
