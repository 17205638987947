import routes from '@/modules/layout/layout-routes';
import store from '@/modules/layout/layout-store';
import layoutGuardMixin from './guards/layout-guard-mixin';

export default {
    components: [],
    filters: [],
    mixins: [layoutGuardMixin],
    routes,
    store,
};
