import { storeAsync } from '@/app-module';

export default {
    async beforeRouteEnter(to, from, next) {
        if (!to.meta || !to.meta.requiresConfirmationKey) {
            next();
            return;
        }
        const store = storeAsync();
        await store.dispatch('auth/doWaitUntilInit');
        if (!to?.query?.confirmationKey) {
            next('/auth/signin');
            return;
        }

        next();
    }
};
