import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const PermissionsList = () => import('@/modules/permissions/pages/PermissionsList.vue');

const getPermissionsModule = () => import('@/modules/permissions/permissions-store');
const importPermissionsStore = () =>
    getPermissionsModule().then((storeModule) => {
        if (!storeAsync().hasModule('permissions')) storeAsync().registerModule('permissions', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'permissions',
                path: '/permissions',
                component: PermissionsList,
                meta: {
                    auth: true,
                    permission: Permissions.values.permissionsRead,
                },
                beforeEnter: (to, from, next) => {
                    importPermissionsStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];

