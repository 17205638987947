import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const DocumentTemplateList = () => import('@/modules/document-template/pages/DocumentTemplateList.vue');
const DocumentTemplateForm = () => import('@/modules/document-template/pages/DocumentTemplateForm.vue');

const getDocumentTemplateModule = () => import('@/modules/document-template/document-template-store');
const importDocumentTemplateStore = () =>
    getDocumentTemplateModule().then((storeModule) => {
        if (!storeAsync().hasModule('documentTemplate')) storeAsync().registerModule('documentTemplate', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'documentTemplate',
                path: '/document-template',
                component: DocumentTemplateList,
                meta: {
                    auth: true,
                    permission: Permissions.values.documentTemplateRead,
                },
                beforeEnter: (to, from, next) => {
                    importDocumentTemplateStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'documentTemplateNew',
                path: '/document-template/new',
                component: DocumentTemplateForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.documentTemplateCreate,
                },
                beforeEnter: (to, from, next) => {
                    importDocumentTemplateStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'documentTemplateEdit',
                path: '/document-template/:id',
                component: DocumentTemplateForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.documentTemplateEdit,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importDocumentTemplateStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
