import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const ComplainTypeList = () => import('@/modules/complain-type/pages/ComplainTypeList.vue');

import { storeAsync } from '../../app-module';
const getComplainTypeModule = () => import('@/modules/complain-type/complain-type-store');
const importComplainTypeStore = () =>
    getComplainTypeModule().then((storeModule) => {
        if (!storeAsync().hasModule('complainType')) storeAsync().registerModule('complainType', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'complain-type',
                path: '/complain-type',
                component: ComplainTypeList,
                meta: {
                    auth: true,
                    permission: Permissions.values.complainTypeRead,
                },
                beforeEnter: (to, from, next) => {
                    importComplainTypeStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
