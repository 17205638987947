const ORG_KEY = 'st_org';

export const geOrgId = () => window.localStorage.getItem(ORG_KEY);

export const saveOrgId = (id) => {
  window.localStorage.setItem(ORG_KEY, id);
};

export const destroyOrgId = () => {
  window.localStorage.removeItem(ORG_KEY);
};

export default { geOrgId, saveOrgId, destroyOrgId };
