import Vue from 'vue';
import Counties from '@/shared/utils/counties';
import ApiService from '@/shared/services/api.service';
import i18n from '@/shared/plugins/vue-i18n';
import { PERMISSIONS_LIST } from '@/security/permissionsMapping';
import Errors from '@/shared/error/errors';
import moment from 'moment';

// action types
export const FETCH_SETTINGS = 'fetchSettings';
export const DOWNLOAD_FILE = 'downloadFile';
export const FETCH_SORTING_PARAMS = 'fetchSortingParams';
export const SET_ACTIVE_LOADING = 'setActiveLoading';
export const SET_INACTIVE_LOADING = 'setInactiveLoading';
export const GET_ORGANISATIONS_LOGO = 'getOrganisationsLogo';
export const SET_ATLAS_PUBLIC_VIEW_STATE = 'setAtlasPublicViewState';
export const DOWNLOAD_FILE_CONTENT = 'downloadFileContent';

// mutation types
export const SET_ORGANISATIONS = 'setOrganisations';
export const SET_ORGANISATION = 'setOrganisation';
export const CLEAR_ORGANISATION = 'clearOrganisation';
export const SET_USER_TYPES = 'setUserTypes';
export const SET_SETTINGS = 'setSettings';
export const SET_SORTING_PARAMS = 'setSortingParams';
export const SET_LOGO_TOKEN = 'setLogoToken';
export const SET_MODULE_NAME = 'setModuleName';
export const SET_ATLAS_PUBLIC_VIEW = 'setAtlasPublicView';

const state = {
    loading: {},
    counties: Counties,
    settings: {},
    defaultAvatar: 'media/users/default.jpg',
    statuses: [
        {
            text: i18n.t('GENERAL.ACTIVE'),
            value: 'active',
        },
        {
            text: i18n.t('GENERAL.INACTIVE'),
            value: 'inactive',
        },
    ],
    organisations: [],
    organisation: {},
    permissions: PERMISSIONS_LIST,
    logoToken: null,
    moduleName: null,
    isAtlasPublicView: false,
    registrationBlacklistedDomains: ['cjcluj.ro']
};

const getters = {
    appConfig: (state) => state.settings,
    loading: (state) => state.loading,
    statuses: (state) => state.statuses,
    defaultCountyId: (state) => {
      const defaultCounty = state.settings.DEFAULT_COUNTY;
      return state.counties.find((el) => el.county === defaultCounty)?.id;
    },
    defaultCountyUatId: (state) => {
        const defaultCounty = state.settings.DEFAULT_COUNTY;
        return state.counties.find((el) => el.county === defaultCounty)?.uat_id;
    },
    registrationBlacklistedDomains: (state) => {
      return state.registrationBlacklistedDomains;
    },
    counties: (state) => state.counties,
    permissions: (state) => state.permissions,
    organisations: (state) => state.organisations,
    organisation: (state) => state.organisation,
    currentDate: (state) => moment(new Date()).format(state.settings.DATE_FORMAT),
    defaultAvatar: (state) => state.defaultAvatar,
    logoToken: (state) => state.logoToken,
    moduleName: (state) => state.moduleName,
    isAtlasPublicView: (state) => state.isAtlasPublicView,
};

const actions = {
    getOrganisations(context) {
        context.commit('shared/activateLoading', 'shared/getOrganisations', { root: true });
        return new Promise(resolve => {
            ApiService.get('organisations?limit=100').then(({data}) => {
                resolve(data);
                context.commit(SET_ORGANISATIONS, data.data);
            }) .catch(({ error }) => {
                reject(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'shared/getOrganisations', { root: true });
            });
        });
    },
    getOrganisation(context, id) {
        context.commit('shared/activateLoading', 'shared/getOrganisation', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get(`organisations/${id}`)
            .then(({ data }) => {
                context.commit(SET_ORGANISATION, data);
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'shared/getOrganisation', { root: true });
            })
        });
    },
    getUserTypes(context) {
        context.commit('shared/activateLoading', 'shared/getUserTypes', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get('user-types').then(({data}) => {
                resolve(data);
                context.commit(SET_USER_TYPES, data.data);
            }).catch(({ error }) => {
                reject(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'shared/getUserTypes', { root: true });
            });
        });
    },
    async [FETCH_SETTINGS](context) {
        const maxRetries = 5; // Maximum number of retries
        const retryDelay = 1000; // Delay between retries in milliseconds
        let retries = 0;

        const fetchSettings = () => {
            context.commit('shared/activateLoading', 'shared/fetchSettings', { root: true });

            return ApiService.get('settings-registry?limit=100')
                .then(({ data }) => {
                    context.commit(SET_SETTINGS, data.data);
                    context.commit('shared/deactivateLoading', 'shared/fetchSettings', { root: true });
                    return Promise.resolve(data.data);
                })
                .catch((error) => {
                    if (error.response.status === 403) {
                        return;
                    }
                    if (retries < maxRetries) {
                        retries++;
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve(fetchSettings()); // Retry the request after delay
                            }, retryDelay);
                        });
                    } else {
                        context.commit('shared/deactivateLoading', 'shared/fetchSettings', { root: true });
                        return Promise.reject(error); // If max retries reached, reject with the error
                    }
                });
        };

        return fetchSettings();
    },
    [DOWNLOAD_FILE](context, { url, fileName, token }) {
        context.commit('shared/activateLoading', 'shared/downloadFile', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.download(url, token).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);

                fileLink.click();

                resolve(response.data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'shared/downloadFile', { root: true });
            });
        });
    },
    [FETCH_SORTING_PARAMS](context, {stateModule, sortingParams}) {
        context.commit(`${stateModule}/setSort`, sortingParams, { root: true }) ;
    },
    [SET_ACTIVE_LOADING](context, key) {
        context.commit('activateLoading', key);
    },
    [SET_INACTIVE_LOADING](context, key) {
        context.commit('deactivateLoading', key);
    },
    [GET_ORGANISATIONS_LOGO](context) {
        context.commit('shared/activateLoading', 'shared/getOrganisationsLogo', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get('/organisations/logos')
            .then((response) => {
                if (response.headers["x-file-download-access-token"]) {
                    context.commit(SET_LOGO_TOKEN, response.headers["x-file-download-access-token"]);
                }
                resolve(response.data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            }).finally(() => {
                context.commit('shared/activateLoading', 'shared/getOrganisationsLogo', { root: true });
            })
        });
    },
    [SET_ATLAS_PUBLIC_VIEW_STATE](context, atlasPublicViewstate) {
        context.commit('SET_ATLAS_PUBLIC_VIEW', atlasPublicViewstate);
    },
    [DOWNLOAD_FILE_CONTENT](context, { url, token }) {
        context.commit('shared/activateLoading', 'shared/downloadFileContent', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.download(url, token).then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject();
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'shared/downloadFileContent', { root: true });
            });
        });
    },
};

const mutations = {
    activateLoading(state, key) {
        Vue.set(state.loading, key, true);
    },
    deactivateLoading(state, key) {
        Vue.set(state.loading, key, false);
    },
    [SET_ORGANISATIONS](state, organisations) {
        state.organisations = organisations;
    },
    [SET_ORGANISATION](state, organisation) {
        state.organisation = organisation;
    },
    [CLEAR_ORGANISATION](state) {
        state.organisation = {};
    },
    [SET_USER_TYPES](state, userTypes) {
        state.userTypes = userTypes;
    },
    [SET_SETTINGS](state, settings) {
        const settingsObject = settings.reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.default_value }), {});
        state.settings = settingsObject;
    },
    [SET_LOGO_TOKEN](state, token) {
        state.logoToken = token;
    },
    [SET_MODULE_NAME](state, name) {
        state.moduleName = name;
    },
    [SET_ATLAS_PUBLIC_VIEW](state, isSelected) {
        state.isAtlasPublicView = isSelected;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
