import { storeAsync } from '@/app-module';
import PermissionChecker from '@/security/permission-checker';
import { routerAsync } from '@/app-module';

export default {
    async beforeRouteEnter(to, from, next) {
        if (!to.meta || !to.meta.permission) {
            next();
            return;
        }
        
        const store = storeAsync();
        await store.dispatch('auth/doWaitUntilInit');
        if (
            new PermissionChecker(
                store.getters['auth/accountDetails']
            ).match(to.meta.permission)
        ) {
            next();
        } else {
            const router = routerAsync();
            router.replace({ name: 'home' });
        }
    }
};
