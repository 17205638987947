import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const TaxCategory = () => import('@/modules/tax-category/pages/TaxCategory.vue');

import { storeAsync } from '../../app-module';
const getTaxCategoryModule = () => import('@/modules/tax-category/tax-category-store');
const importTaxCategoryStore = () =>
    getTaxCategoryModule().then((storeModule) => {
        if (!storeAsync().hasModule('taxCategory')) storeAsync().registerModule('taxCategory', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'tax-category',
                path: '/tax-category',
                component: TaxCategory,
                meta: {
                    auth: true,
                    permission: Permissions.values.taxCategoryRead,
                },
                beforeEnter: (to, from, next) => {
                    importTaxCategoryStore().then(() => {
                        next();
                    });
                }
            },
        ],
    },
];
