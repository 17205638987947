const Layout = () => import('@/modules/layout/components/layout');
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const Accounts = () => import('@/modules/accounts/pages/Accounts.vue')
const getAccountsModule = () => import('@/modules/accounts/accounts-store');
const importAccountsStore = () =>
getAccountsModule().then((storeModule) => {
        if (!storeAsync().hasModule('accounts')) {
            storeAsync().registerModule('accounts', storeModule.default);
        }
        else return;
    });
export default [
    {
        path: '',
        exact: true,
        component: Layout,
        children: [
            {
                name: 'accounts',
                path: '/accounts',
                component: Accounts,
                exact: true,
                meta: {
                    auth: true,
                    permission: Permissions.values.accountsRead,
                },
                beforeEnter: (to, from, next) => {
                    importAccountsStore().then(() => {
                        next();
                    });
                },
            },
        ],
    }
]
