export default function urc() {
    return {
        validate(input) {
            const cui = input.value;
            let cif = cui;

            // Daca este string, elimina atributul fiscal si spatiile
            if (isNaN(parseFloat(cui)) || !isFinite(cui)) {
                cif = cif.toUpperCase();
                if (cif.indexOf('RO') === 0) {
                    cif = cif.replace('RO', '');
                } else {
                    return {
                        valid: false,
                    };
                }
                cif = parseInt(cif.trim());
            } else {
                cif = parseInt(cif);
            }

            // Daca are mai mult de 10 cifre sau mai putin de 6, nu-i valid
            if(cif.toString().length > 10 || cif.toString().length < 6){
                return {
                    valid: false,
                };
            }
            // numarul de control
            var v = 753217532;

            // extrage cifra de control
            var c1 = parseInt(cif % 10);
            cif = parseInt(cif / 10);

            // Executa operatiile pe cifre
            var t = 0;
            while (cif > 0) {
                t += (cif % 10) * (v % 10);
                cif = parseInt(cif / 10);
                v = parseInt(v / 10);
            }

            // Aplica inmultirea cu 10 si afla modulo 11
            var c2 = parseInt((t * 10) % 11);

            // Daca modulo 11 este 10, atunci cifra de control este 0
            if(c2 == 10){
                c2 = 0;
            }

            if (c1 !== c2) {
                return {
                    valid: false,
                };
            }

            return {
                valid: true,
            }
        }
    }
}
