const ID_LANGUAGE_KEY = 'st_language';

const i18nService = {
  defaultLanguage: 'ro',

  languages: [
    {
      lang: 'ro',
      name: 'Romana',
      flag: `${process.env.BASE_URL}media/svg/flags/109-romania.svg`,
    },
    // {
    //   lang: 'hu',
    //   name: 'Magyar',
    //   flag: `${process.env.BASE_URL}media/svg/flags/115-hungary.svg`,
    // },
    // {
    //   lang: 'en',
    //   name: 'English',
    //   flag: `${process.env.BASE_URL}media/svg/flags/260-united-kingdom.svg`,
    // },
  ],

  /**
     * Keep the active language in the localStorage
     * @param lang
     */
  setActiveLanguage(lang) {
    localStorage.setItem(ID_LANGUAGE_KEY, lang);
  },

  /**
     * Get the current active language
     * @returns {string | string}
     */
  getActiveLanguage() {
    return localStorage.getItem(ID_LANGUAGE_KEY) || this.defaultLanguage;
  },
};

export default i18nService;
