import ApiService from '@/shared/services/api.service';
import moment from 'moment';

// action types
export const GET_USER_MESSAGES = 'getUserMessages';
export const UPDATE_USER_MESSAGE = 'updateUserMessage';

// mutation types
export const SET_USER_MESSAGES = 'setUserMessages';
export const SET_LIST_PERIOD = 'setListPeriod';
export const SET_UNREAD_NOTIFICATIONS = 'setUnreadNotifications';

const now = new Date()
const endDate = moment(now).add(1, 'day').format('YYYY-MM-DD');
const startMonth = moment(now).subtract(1, 'months').format('YYYY-MM-DD')

const state = {
    userMessages: [],
    listPeriod: {
        startDate: startMonth,
        endDate: endDate
    },
    unreadNotifications: false
};

const getters = {
    userMessages: (state) => state.userMessages,
    listPeriod: (state) => state.listPeriod,
    unreadNotifications: (state) => state.unreadNotifications
};

const mutations = {
    [SET_USER_MESSAGES](state, userMessages) {
        state.userMessages = userMessages;
    },
    [SET_LIST_PERIOD](state, listPeriod) {
        state.listPeriod = listPeriod;
    },
    [SET_UNREAD_NOTIFICATIONS](state, unread) {
        state.unreadNotifications = unread;
    }
};

const actions = {
    [GET_USER_MESSAGES](context, period) {
        context.commit('shared/activateLoading', 'notifications/getUserMessages', { root: true });
        return new Promise((resolve, reject) => {
            const {endDate, startDate} = period;
            const query = `created_date ge '${startDate}' and created_date lt '${endDate}'`

            ApiService.query('/user-messages', {params: {
                    query,
                    sort: '-created_date',
                    limit: 100
                }
            })
            .then(({ data }) => {
                //unread in the last 30 minutes
                const unread = data.data.find((el) => { 
                    let minutes = moment.duration(moment().diff(el.created_date)).asMinutes();

                    return minutes <= 30 && !el.has_been_read;
                });

                context.commit(SET_UNREAD_NOTIFICATIONS, unread ? true : false);

                context.commit(SET_USER_MESSAGES, data.data);
                resolve(data);
            })
            .catch(({ error }) => {
                reject(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'notifications/getUserMessages', { root: true });
            })
        });
    },
    [UPDATE_USER_MESSAGE](context, id) {
        context.commit('shared/activateLoading', 'notifications/updateUserMessage', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch(`/user-messages/${id}`, {'has_been_read': true})
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ error }) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'notifications/updateUserMessage', { root: true });
                })
        });
    },
};



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
