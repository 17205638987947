import { storeAsync } from '@/app-module';

export default {
    async beforeRouteEnter(to, from, next) {
        if (!to.meta || !to.meta.requiresPhoneVerification) {
            next();
            return;
        }
        const store = storeAsync();
        await store.dispatch('auth/doWaitUntilInit');

        if (to.query && to.query.token) {
            next();
            return;
        }

        if (!store.getters['auth/isAuthSucceeded']) {
            next('/auth/signin');
            return;
        }

        if (
            store.getters['auth/isEmailConfirmed'] &&
            store.getters['auth/isPhoneConfirmed']
        ) {
            next('/');
        } else {
            next();
        }
    }
};
