<template>
    <div>
        <b-input-group class="mb-3">
            <b-input-group-prepend>
               <b-form-datepicker
                v-bind="labels[locale] || {}"
                :placeholder="$t('GENERAL.DATEPICKER.SELECT_DATE')"
                :date-format-options="formatOptions"
                :locale="locale"
                :start-weekday="1"
                :min="min"
                :max="max"
                :disabled="disabled"
                :readonly="readonly"
                :size="size"
                :style="style"
                class="st-button-only-datepicker"
                button-only
                left
                selected-variant="primary"
                v-model="datepicker"
                @input="onChange()"
                aria-controls="st-date-input"
            />
            </b-input-group-prepend>

            <b-form-input
                id="st-date-input"
                :name="name"
                v-model="date"
                :disabled="disabled"
                :readonly="readonly"
                :type="type"
                class="form-control"
                @keydown="keydownHandler"
            ></b-form-input>
        </b-input-group>
    </div>

</template>

<script>
import i18nService from '@/shared/services/i18n.service';
import { BInputGroupPrepend } from 'bootstrap-vue'
import moment from 'moment';
export default {
    name: 'StButtonOnlyDatePicker',
    components: {
        'b-input-group-prepend': BInputGroupPrepend,
    },
    data() {
        return {
            locale: i18nService.getActiveLanguage(),
            style: {},
            labels :{
                ro: {
                    labelNoDateSelected: this.$t('GENERAL.DATEPICKER.SELECT_DATE'),
                    labelHelp: this.$t('GENERAL.DATEPICKER.HELP')
                }
            },
            isSet: false,
            date: '',
            enableKeyDown: false
        }
    },
    props: {
        value: {
            type: [String, Number],
        },
        formatOptions: {
            type: Object,
            default: () => ({
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            })
        },
        name: {
            type: String,
            required: true,
        },
        min: {
            type: Date,
        },
        max: {
            type: Date,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'md',
        },
        type: {
            type: String,
            default: 'text',
        }
    },
    computed: {
        datepicker: {
            get() {
                return this.value;
            },
            set(val) {
                this.date = this.formatDate(val)
                this.isSet = true;
                this.$emit('input', val);
            },
        },
    },
    watch: {
        datepicker(val) {
            if (!this.isSet) {
                this.date = this.formatDate(val);
            }
        },
        date(val) {
            const isDateValid = moment(val,'DD-MM-YYYY', true).isValid();
            if (isDateValid && this.enableKeyDown) {
                const formatedDate = moment(val,'DD-MM-YYYY').format('YYYY-MM-DD');
                this.$emit('input', formatedDate);
            }
        }
    },
    mounted() {
        if (this.size === 'sm') {
            this.style = {
                paddingTop: '2px',
                paddingBottom: '2px'
            }
        }
    },
    methods: {
        onChange(val) {
            this.$emit('change');
        },
        formatDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        keydownHandler() {
            this.enableKeyDown = true;
        }
    },
}
</script>
