<template>
    <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
        <div class="alert alert-custom alert-maintenance alert-light-warning py-0"  v-if="isMaintenance" role="alert">
            <div class="d-flex align-items-center">
                <i class="fas fa-exclamation-triangle text-warning"></i>
            </div>
            <div class="alert-text text-center">
                <span class="font-size-sm py-4">  {{ maintenanceText ? maintenanceText : $t('GENERAL.MAINTENANCE') }} </span>
            </div>
            <div class="d-flex align-items-center">
                <i class="fas fa-exclamation-triangle text-warning"></i>
            </div>
        </div>
        <!-- login modal used when token expires -->
        <login-modal ref="login-modal"></login-modal>

        <!-- begin:: Header Mobile -->
        <HeaderMobile></HeaderMobile>
        <!-- end:: Header Mobile -->
        <!-- <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader> -->
        <div class="d-flex flex-row flex-column-fluid page">
            <StAside v-if="asideEnabled" :isMaintenance="isMaintenance"></StAside>
            <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper" :class="{ 'fill-content': isAtlas }">
            <Header v-if="!isAtlas" :isMaintenance="isMaintenance"></Header>
            <FloatingHeader v-else></FloatingHeader>
            <div id="kt_content"
                class="content d-flex flex-column flex-column-fluid"
                :class="{'layout-custom-content': isMaintenance}"
            >
                <div class="d-flex flex-column-fluid">
                <div
                    :class="{
                        'container-fluid': contentFluid,
                        container: !contentFluid,
                    }"
                >
                    <transition name="fade" mode="out-in">
                        <router-view />
                    </transition>
                </div>
                </div>
            </div>
            <Footer v-if="!isAtlas"></Footer>
        </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import StAside from '@/modules/layout/components/aside/Aside.vue';
import Header from '@/modules/layout/components/header/Header.vue';
import HeaderMobile from '@/modules/layout/components/header/HeaderMobile.vue';
import Footer from '@/modules/layout/components/footer/Footer.vue';
import LoginModal from '@/shared/modals/LoginModal.vue'
import FloatingHeader from '@/modules/layout/components/header/FloatingHeader.vue';

import HtmlClass from '@/shared/services/htmlclass.service';

import GlobalComponents from '@/shared/globalComponents';
const administratorModule = () => import('@/modules/administrator/administrator-store');
const archiveModule = () => import('@/modules/archive/archive-store');

export default {
  name: 'app-layout',
  components: {
    StAside,
    HeaderMobile,
    Header,
    Footer,
    LoginModal,
    FloatingHeader
  },
  data() {
    return {
        // asideEnabled: true
    };
  },
  beforeMount() {
    // show page loading
    // this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  async mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
        this.$router.push({ name: 'signin' });
    }

    if (this.isTokenExpired) {
      this.$bvModal.show('login-modal');
    }

    // this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
  },
  methods: {
      ...mapActions({
          fetchSettings: 'shared/fetchSettings',
      })
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      isTokenExpired: 'auth/isTokenExpired',
      selectedMenu: 'selectedMenu',
      isSuperAdmin: 'auth/isSuperAdmin',
      appConfig: 'shared/appConfig',
    }),
    ...mapGetters([
      // 'breadcrumbs',
      // 'pageTitle',
      'layoutConfig',
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid';
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display');
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display');
    },
    isAtlas() {
      return this.selectedMenu === 'atlas';
    },
    isMaintenance() {
        return this.appConfig.MAINTENANCE;
    },
    maintenanceText() {
      return this.appConfig.MAINTENANCE_TEXT;
    }
  },
  async created() {
    Vue.use(GlobalComponents);
    if (this.isSuperAdmin && !this.$store.hasModule('administrator')) {
      const storeModule = await administratorModule();
      await this.$store.registerModule('administrator', storeModule.default);
    }
    if (this.isSuperAdmin && !this.$store.hasModule('archive')) {
        const archiveStoreModule = await archiveModule();
        await this.$store.registerModule('archive', archiveStoreModule.default);
    }
  },
};
</script>

<style>
</style>
