<style scoped lang="scss" src="@/assets/sass/pages/error/error-page.scss"></style>

<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="error error-page-wrapper d-flex flex-row-fluid bgi-size-cover bgi-position-center"
      :style="{backgroundImage: `url(${backgroundImage})`}"
    >
      <!-- begin:: Content -->
      <div class="d-flex flex-column flex-row-fluid text-center">
        <h2 class="error-title font-weight-boldest text-white mb-12">
          {{ $t("GENERAL.ERROR_PAGE.TITLE") }}
        </h2>
        <div class="display-4 font-weight-bold text-white">
            <p>{{ $t("GENERAL.ERROR_PAGE.TEXT") }}</p>
            <p>{{ $t("GENERAL.ERROR_PAGE.SUB_TEXT") }}</p>
        </div>
      </div>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
export default {
    name: 'ErrorPage',
    data() {
        return {
            backgroundImage: process.env.BASE_URL + 'media/errors/general-error.jpg'
        }
    },
};
</script>
