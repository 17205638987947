import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const MetadataMapping = () => import('@/modules/metadata-mapping/pages/MetadataMapping.vue');

const getMetadataMappingModule = () => import('@/modules/metadata-mapping/metadata-mapping-store');
const importMetadataMappingStore = () =>
    getMetadataMappingModule().then((storeModule) => {
        if (!storeAsync().hasModule('metadataMapping')) storeAsync().registerModule('metadataMapping', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'metadata-mapping',
                path: '/metadata-mapping',
                component: MetadataMapping,
                meta: {
                    auth: true,
                    permission: Permissions.values.metadataMappingRead,
                },
                beforeEnter: (to, from, next) => {
                    importMetadataMappingStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];

