const calcSortParams = (params) => {
    const sortParams = {}
    const { sortBy, sortDesc } = params;
    const order = sortDesc ? '-' : '';
    if (sortBy) sortParams.sort = `${order}${sortBy}`;

    return sortParams;
}

export default calcSortParams;
