import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class ApplicationTypePermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.applicationTypeRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.applicationTypeCreate,
        );
        this.edit = permissionChecker.match(
            Permissions.values.applicationTypeEdit,
        );
        this.destroy = permissionChecker.match(
            Permissions.values.applicationTypeDestroy,
        );
    }
}
