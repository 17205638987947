import axios from "axios";
import { eventHub } from '@/shared/utils/eventHub';
import i18nService from './i18n.service';
import * as JwtService from '@/shared/services/jwt.service';
import { storeAsync } from "@/app-module";
import archiveConfig from '@/config/archive.config.js';
import Roles from '@/security/roles';

export default function setupInterceptors() {
    //request interceptor
    axios.interceptors.request.use(
        async (options) => {
            const store = storeAsync();
            const selectedMenu = store.getters['selectedMenu'];
            const manualUploadSettings = await store.getters['archive/document/manualUploadSettings'];
            const isMenuArchive = selectedMenu === 'archive';
            if (isMenuArchive && !manualUploadSettings.uploadFileToGUArchive) {
                if (options.url === '/ArchiveDocument/downloadArchivedDocumentAttachment') {
                    options.responseType = 'blob';
                }
                const currentUser = await store.getters['auth/currentUser'];
                const isSuperUserEmail = currentUser.email.includes('super');
                const userEmail = currentUser.user_type_name === Roles.values['super_admin'] && isSuperUserEmail ?
                                archiveConfig.manualUploadEmail : currentUser.email;
                options.headers['x-user-email'] = manualUploadSettings.manualArchive ? archiveConfig.manualUploadEmail : userEmail;
                const token = await JwtService.getToken();
                if (token) {
                    options.headers['x-auth-token'] = token;
                }
            } else {
                options.headers['Access-Control-Allow-Origin'] = '*';
                options.headers['Cache-Control'] = 'max-age=0';

                const token = await JwtService.getToken();

                if (token) {
                    options.headers['x-auth-token'] = token;
                }

                options.headers['Accept-Language'] = i18nService.getActiveLanguage();
                // TODO: Used for testing complains
                options.headers['x-auth-user-id'] = store.getters['auth/currentUser']?.user_id;
            }

            return options;
        },

        (error) => {
            return Promise.reject(error);
        }
    );

    //response interceptor
    axios.interceptors.response.use(
        (response) => {
            if (response.status === 200 || response.status === 201 || response.status === 204) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        },

        (error) => {
            if (error?.response?.status) {
                switch (error.response.status) {
                    case 400:
                        break;
                    case 401:
                        break;
                    case 403:
                        //invalid token, prompt login pop-up
                        eventHub.$emit('expiredToken', true);
                        break;
                    case 404:
                        break;
                    case 502:
                        break;
                }

                return Promise.reject(error);
            } else {
                //network error
                return Promise.reject(error);
            }
        }
    );
}
