import ErrorPage from '@/modules/layout/components/ErrorPage.vue';

export default [
    {
        name: 'error403',
        path: '/403',
        component: ErrorPage,
    },
    {
        name: 'error404',
        path: '/404',
        component: ErrorPage,
    },
    {
        name: 'error500',
        path: '/500',
        component: ErrorPage,
    },
];
