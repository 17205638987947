import { render, staticRenderFns } from "./QuickUser.vue?vue&type=template&id=54bd871a&scoped=true&"
import script from "./QuickUser.vue?vue&type=script&lang=js&"
export * from "./QuickUser.vue?vue&type=script&lang=js&"
import style0 from "./QuickUser.vue?vue&type=style&index=0&id=54bd871a&prod&lang=scss&scoped=true&"
import style1 from "@/assets/sass/pages/profile/quick-user.scss?vue&type=style&index=1&id=54bd871a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54bd871a",
  null
  
)

export default component.exports