import objectPath from 'object-path';
import merge from 'deepmerge';
import config from '@/config/layout.config.json';
import MenuService from '@/shared/services/menu.service';
import { ApplicationsService } from '@/modules/applications/applications-service';
import Errors from '@/shared/error/errors';

// action types
export const SET_LAYOUT_CONFIG = 'setLayoutConfig';
export const RESET_LAYOUT_CONFIG = 'resetLayoutConfig';
export const OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig';
export const OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig';

export const ADD_BODY_CLASSNAME = 'addBodyClassName';
export const REMOVE_BODY_CLASSNAME = 'removeBodyClassName';
export const ADD_CLASSNAME = 'addClassName';

export const SELECT_MENU = 'setMenu';

export const GET_ASSIGNED_APPLICATIONS_COUNT = 'getAssignedApplicationsCount';
// mutation types
export const SET_CLASSNAME_BY_POSITION = 'setClassNameByPosition';
export const SET_SELECTED_MENU = 'setSelectedMenu';

export const SET_ASSIGNED_APPLICATIONS_COUNT = 'setAssignedApplicationsCount';
export const SET_INTERNAL_DOCUMENTS_COUNT = 'setInternalDocumentsCount';


const DEFAULT_MENU = 'default';

export default {
    state: {
        config: config,
        initial: config,
        classes: {},
        menu: DEFAULT_MENU,
        assignedApplicationsCount: 0,
        internalDocumentsCount: 0,
    },
    getters: {
        /**
         * Get config from layout config
         * @param state
         * @returns {function(path, defaultValue): *}
         */
        layoutConfig: (state) => (path, defaultValue) => {
            return objectPath.get(state.config, path, defaultValue);
        },
        getClasses: (state) => (position) => {
            if (typeof position !== 'undefined') {
                return state.classes[position];
            }
            return state.classes;
        },
        selectedMenu: (state) => state.menu,
        assignedApplicationsCount: (state) => state.assignedApplicationsCount,
        internalDocumentsCount: (state) => state.internalDocumentsCount,
    },
    actions: {
        /**
         * Set and replace the whole config
         * @param state
         * @param payload
         */
        [SET_LAYOUT_CONFIG](state, payload) {
            state.commit(SET_LAYOUT_CONFIG, payload);
        },

        /**
         * Reset the config at the initial state
         * @param state
         */
        [RESET_LAYOUT_CONFIG](state) {
            state.commit(RESET_LAYOUT_CONFIG);
        },

        /**
         * Reset the config using saved config in the cache by the layout builder
         * @param state
         */
        [OVERRIDE_LAYOUT_CONFIG](state) {
            state.commit(OVERRIDE_LAYOUT_CONFIG);
        },

        /**
         * Override config by page level
         * @param state
         * @param payload
         */
        [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
            state.commit(OVERRIDE_PAGE_LAYOUT_CONFIG, payload);
        },
        [ADD_BODY_CLASSNAME](context, className) {
            document.body.classList.add(className);
        },
        [REMOVE_BODY_CLASSNAME](context, className) {
            document.body.classList.remove(className);
        },
        [ADD_CLASSNAME](context, payload) {
            context.commit(SET_CLASSNAME_BY_POSITION, payload);
        },
        async [SELECT_MENU](context, selectedMenu) {
            await MenuService.saveMenuStatus(selectedMenu);
            context.commit(SET_SELECTED_MENU, selectedMenu);
        },
        [GET_ASSIGNED_APPLICATIONS_COUNT](context, payload) {
            return new Promise((resolve, reject) => {
                context.commit('shared/activateLoading', 'menu/getAssignedApplicationCount', { root: true });
                const { params } = payload;
                let filters = { params: { ...params } };
                ApplicationsService.listStaff(filters)
                .then((data) => {
                    if (filters.params.internal && filters.params.assigned) {
                        context.commit(SET_INTERNAL_DOCUMENTS_COUNT, data?.pagination?.total);
                    }

                    if (filters.params.assigned && !filters.params.internal) {
                        context.commit(SET_ASSIGNED_APPLICATIONS_COUNT, data?.pagination?.total);
                    }
                    
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'menu/getAssignedApplicationCount', { root: true });
                });

            });
        }
    },
    mutations: {
        [SET_LAYOUT_CONFIG](state, payload) {
            state.config = payload;
        },
        [RESET_LAYOUT_CONFIG](state) {
            state.config = Object.assign({}, state.initial);
        },
        [OVERRIDE_LAYOUT_CONFIG](state) {
            state.config = state.initial = Object.assign(
                {},
                state.initial,
                JSON.parse(localStorage.getItem('config')),
            );
        },
        [OVERRIDE_PAGE_LAYOUT_CONFIG](state, payload) {
            state.config = merge(state.config, payload);
        },
        [SET_CLASSNAME_BY_POSITION](state, payload) {
            const { position, className } = payload;
            if (!state.classes[position]) {
                state.classes[position] = [];
            }
            state.classes[position].push(className);
        },
        [SET_SELECTED_MENU](state, menu) {
            state.menu = menu || DEFAULT_MENU;
        },
        [SET_ASSIGNED_APPLICATIONS_COUNT](state, count) {
            state.assignedApplicationsCount = count ?? 0;
        },
        [SET_INTERNAL_DOCUMENTS_COUNT](state, count) {
            state.internalDocumentsCount = count ?? 0;
        }
    },
};
