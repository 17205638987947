export default class PermissionChecker {
    constructor(currentUser) {
        this.currentUser = currentUser;
        // TODO: use roles instead of user_type_name
        this.userRoles = currentUser ? [currentUser.user_type_name] : [];
        this.userPermissions = currentUser?.permissions ? Object.keys(currentUser.permissions) : [];
    }

    match(permission) {
        if (!permission) {
            return true;
        }
        
        // const matchRole = this.rolesMatchOneOf(permission.allowedRoles);
        const matchPermission = this.permissionsMatchOneOf(permission.allowedPermissions)
        return matchPermission;
    }

    rolesMatchOneOf(arg) {
        if (!this.userRoles) {
            return false;
        }

        if (!arg) {
            return false;
        }

        if (Array.isArray(arg)) {
            if (!arg.length) {
                return false;
            }

            return arg.some((role) =>
                this.userRoles.includes(role),
            );
        }

        return this.userRoles.includes(arg);
    }

    permissionsMatchOneOf(arg) {
        if (!this.userPermissions) {
            return false;
        }

        if (!arg) {
            return false;
        }

        if (Array.isArray(arg)) {
            if (!arg.length) {
                return false;
            }

            return arg.some((permission) =>
                this.userPermissions.includes(permission),
            );
        }

        return this.userPermissions.includes(arg);
    }
}
