import ApiService from '@/shared/services/api.service';

// action types
export const GET_NOTIFICATIONS_PREF = 'getNotificationsPreferences';
export const GET_NOTIFICATIONS_TYPE = 'getNotificationsType';
export const UPDATE_NOTIFICATIONS_PREF = 'updateNotificationsPreferences';

// mutation types
export const SET_NOTIFICATIONS_PREF = 'setNotificationsPref';
export const SET_NOTIFICATIONS_TYPE = 'setNotificationsType';

const state = {
    notificationsPreferences: [],
    notificationsType: {}
};

const getters = {
    notificationsPreferences (state) {
        return state.notificationsPreferences;
    },
    notificationsTypes (state) {
        return state.notificationsType;
    },

};

const actions = {
    [GET_NOTIFICATIONS_PREF](context) {
        context.commit('shared/activateLoading', 'notifications/management/getNotifications', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get('notifications/preferences')
                .then(({ data }) => {
                    context.commit(SET_NOTIFICATIONS_PREF, data);
                    resolve(data);
                })
                .catch(({ error }) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'notifications/management/getNotifications', { root: true });
                })
        });
    },
    [GET_NOTIFICATIONS_TYPE](context) {
        context.commit('shared/activateLoading', 'notifications/getNotificationsType', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get('notifications/types')
                .then(({ data }) => {
                    context.dispatch('getNotificationsPreferences')
                    .then((data1)=> {
                        resolve(data1);
                    })
                    context.commit(SET_NOTIFICATIONS_TYPE, data);
                })
                .catch(({ error }) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'notifications/getNotificationsType', { root: true });
                })
        });
    },
    [UPDATE_NOTIFICATIONS_PREF](context, notifications) {
        context.commit('shared/activateLoading', 'notifications/management/updateNotifications', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.put('notifications/preferences', notifications)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ error }) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'notifications/management/updateNotifications', { root: true });
                })
        });
    },
};

const mutations = {
    [SET_NOTIFICATIONS_PREF](state, notificationsPreferences) {
        state.notificationsPreferences = notificationsPreferences;
    },
    [SET_NOTIFICATIONS_TYPE](state, notifications) {
        state.notificationsType = notifications;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
