import notificationsManagementStore from '@/modules/notifications/store/notifications-management-store';
import notificationsTemplatesStore from '@/modules/notifications/store/notifications-templates-store';
import notificationsInApp from '@/modules/notifications/store/notifications-in-app-store';

export default {
    namespaced: true,
    modules: {
        management: notificationsManagementStore,
        templates: notificationsTemplatesStore,
        inApp: notificationsInApp,
    },
};
