export default {
  props: {
    ariaLabel: {
      type: String,
      default: null,
    },
    ariaLabelledBy: {
      type: String,
      default: null,
    },
    ariaDescribedBy: {
      type: String,
      default: null,
    },
    ariaRequired: {
      type: Boolean,
      default: false,
    },
    ariaInvalid: {
      type: Boolean,
      default: false,
    },
    ariaRole: {
      type: String,
      default: null,
    },
  }
}
