import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const ControlTypeList = () => import('@/modules/control-type/pages/ControlTypeList.vue');

const getControlTypeModule = () => import('@/modules/control-type/control-type-store');
const importControlTypeStore = () =>
    getControlTypeModule().then((storeModule) => {
        if (!storeAsync().hasModule('controlType')) storeAsync().registerModule('controlType', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'control-type',
                path: '/control-type',
                component: ControlTypeList,
                meta: {
                    auth: true,
                    permission: Permissions.values.controlTypeRead,
                },
                beforeEnter: (to, from, next) => {
                    importControlTypeStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
