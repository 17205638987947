import { storeAsync } from '@/app-module';
import { CHECK_VALIDATION_TOKEN } from '../auth-store';

export default {
    async beforeRouteEnter(to, from, next) {
        if (!to.meta || !to.meta.requiresTokenVerification) {
            next();
            return;
        }

        const store = storeAsync();
        const token = to.query.token || store.getters['auth/verificationToken'];

        try {
            await store.dispatch(`auth/${CHECK_VALIDATION_TOKEN}`, token);
            next();
        } catch (err) {
            next({ name: 'invalidLink' });
        }
    }
}