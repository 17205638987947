import { storeAsync } from '@/app-module';
import { routerAsync } from '../../../app-module';

export default {
    async beforeRouteEnter(to, from, next) {
        if (!to.meta || !to.meta.auth) {
            next();
            return;
        }

        const store = storeAsync();
        await store.dispatch('auth/doWaitUntilInit');

        if (to.meta.authOrg) {
            const currentUserOrganisationId = store.getters['auth/currentUserOrganisationId'];
            if (to.query.id !== currentUserOrganisationId) {
                next({ path: '/home' });
                return;
            }
        }

        if (!store.getters['auth/isAuthenticated'] &&
            !store.getters['auth/isAuthSucceeded']
        ) {
            next({ path: '/auth/signin' });
            return;
        }

        if (
            to.path !== '/auth/confirm-email' &&
            !store.getters['auth/isEmailConfirmed']
        ) {
            next({ path: '/auth/confirm-email' });
            return;
        }

        if (to.path !== '/auth/verify-sms' &&
            store.getters['auth/isEmailConfirmed'] &&
            !store.getters['auth/isPhoneConfirmed']
        ) {
            next({ path: '/auth/verify-sms' });
            return;
        }

        next();
    }
};
