import moment from 'moment';

class IsPersonalNumericCodeConstraint {

    MIN_AGE = 18;

    calcPersonalDate(firstDigit, birthDate) {
        moment.parseTwoDigitYear = (yearString) => {
            const year = parseInt(yearString);

            if (firstDigit === '3' || firstDigit === '4') {
                return 1800 + year;
            }

            if (firstDigit === '5' || firstDigit === '6') {
                return 2000 + year;
            }

            return 1900 + year;
        };

        return moment(birthDate, 'YYMMDD');
    }

    isAgeValid(personalDate, currentDate) {
        const personalAge = moment(currentDate).diff(personalDate, 'years');

        return personalAge >= this.MIN_AGE;
    }

    isPersonalDateValid(firstDigit, birthDate) {
        const personalDate = this.calcPersonalDate(firstDigit, birthDate);
        const currentDate = new Date();
        
        if(!personalDate.isValid()) {
            return false;
        }
        
        if (!personalDate.isBefore(currentDate)) {
            return false;
        }

        return this.isAgeValid(personalDate, currentDate);
    }

    validate(value) {
        const personalNumbericCodeRegexp = new RegExp('^([1-9])(\\d{6})\\d{6}$');
        const isValid = personalNumbericCodeRegexp.test(value);

        if (!isValid) {
            return false;
        }

        const values = personalNumbericCodeRegexp.exec(value);
        const firstDigit = values[1];
        const birthDate = values[2];

        return this.isPersonalDateValid(firstDigit, birthDate);
    }
}

export default function pnc() {
    return {
        validate(input) {
            const value = input.value;
            const valid = new IsPersonalNumericCodeConstraint().validate(value);

            return {
                valid,
            };
        }
    }
}
