import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const ComplainList = () => import('@/modules/complain/pages/ComplainList.vue');
const ComplainForm = () => import('@/modules/complain/pages/ComplainForm.vue');

const getComplainModule = () => import('@/modules/complain/complain-store');
const importComplainStore = () =>
    getComplainModule().then((storeModule) => {
        if (!storeAsync().hasModule('complain')) storeAsync().registerModule('complain', storeModule.default);
        else return;
    });


export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'complain',
                path: '/complain',
                component: ComplainList,
                meta: {
                    auth: true,
                    permission: Permissions.values.complainView,
                },
                beforeEnter: (to, from, next) => {
                    importComplainStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'complainNew',
                path: '/complain/new',
                component: ComplainForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.complainCreate,
                },
                beforeEnter: (to, from, next) => {
                    importComplainStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'complainView',
                path: '/complain/:id',
                component: ComplainForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.complainView,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importComplainStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
