<template>
    <div>
        <Loader v-if="isLoading"/>
        <ul class="menu-nav custom-menu-nav">
            <li class="row justify-content-center mx-5 py-5" v-if="false">
                <st-button
                    v-can:create="'archive'"
                    variant="warning"
                    :block="true"
                    :callback="manualArchive"
                    id="btn_create_request"
                    customClass="text-dark font-size-h5"
                >
                    <i class="fas fa-plus text-dark"/>
                    {{$t('ARCHIVE.BUTTONS.MANUAL_ARCHIVE')}}
                </st-button>
            </li>

            <li class="menu-section">
                <h4 class="menu-text">Arhiva</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/archive-dashboard">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-th-large"/>
                        <span class="menu-text">{{$t('ARCHIVE.MENU.DASHBOARD')}}</span>
                    </a>
                </li>
            </router-link>
            <li class="pl-8"
                :class= "{ 'disable-tree' : isLoading }"
            >
                <ul id="tree-view-section" class="mx-0 px-0">
                    <tree-item
                        class="item"
                        :item="treeData"
                        @make-folder="makeFolder"
                        @add-item="addItem"
                        @expand="expand"
                    ></tree-item>
                </ul>
            </li>
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
            >
                <a href="href" class="menu-link" @click.prevent="goToArchiveApp">
                    <i class="menu-icon fas fa-cog"/>
                    <span class="menu-text">Administrare</span>
                </a>
            </li>
            <li>
                <hr/>
            </li>
            <li class="menu-section">
                <h4 class="menu-text">Aplicatii</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/atlas"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-map"/>
                        <span class="menu-text">{{ $t('MENU.ATLAS') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-slot="{ href, isActive, isExactActive }"
                to="/dashboard"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click.prevent="goToHome">
                        <i class="menu-icon fas fa-archive"/>
                        <span class="menu-text">Ghiseul Unic</span>
                    </a>
                </li>
            </router-link>
            <li v-if="false">
                <hr/>
            </li>
            <!-- Help Section -->
            <li class="menu-section" v-if="false">
                <h4 class="menu-text">{{ $t('MENU.HELP') }}</h4>
                <i class="menu-icon flaticon-more-v2"/>
            </li>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/manual">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon flaticon2-open-text-book"/>
                        <span class="menu-text">{{ $t('MENU.MANUAL') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/faq">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon far fa-question-circle"/>
                        <span class="menu-text">{{ $t('MENU.FAQ') }}</span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="false"
                v-slot="{ href, navigate, isActive, isExactActive }"
                to="/gdpr">
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <i class="menu-icon fas fa-book"/>

                        <span class="menu-text">GDPR</span>
                    </a>
                </li>
            </router-link>
        </ul>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import TreeItem from '@/modules/archive/components/treeview/TreeItem';
    import Vue from 'vue';
    export default {
        components: { TreeItem },
        name: 'MenuArchive',
        data() {
            return {
                treeData: {},
                selectedWorkspace: ''
            }
        },
        computed: {
            ...mapState({
                loading: state => state.shared.loading,
            }),
            ...mapGetters({
                currentUser: 'auth/currentUser',
                workspaces: 'archive/workspaces/rows',
                folders: 'archive/subfolders/rows',
                subfolders:  'archive/subfolders/subfolders',
            }),
            isLoading () {
                return this.loading['archive/getWokspaces'] || this.loading['archive/getSubfolders'];
            },
        },
        created() {
            this.doFetch({offset: 0, pageSize: 10})
            .then(() => {
                const children = this.workspaces.map(row => {
                    return {
                        name: row.name,
                        documentLocationRealName: row.documentLocationRealName,
                        workspace:row,
                        isWorkspace: true,
                        children: []
                    }
                })
                this.treeData = {
                    name: "Documente",
                    isFirst: true,
                    children
                }
            })
        },
        methods: {
            ...mapActions({
                doFetch: 'archive/workspaces/getWorkspaces',
                doFetchFolders: 'archive/subfolders/getSubfolders',
                doFetchFoldersFromNode: 'archive/subfolders/getSubfoldersFromNode',
                doUpdateBreadcrumbs: 'archive/workspaces/updateBreadcrumbs',
                clearSort: 'archive/documents/clearSort',
                clearWorkspacesSort: 'archive/workspaces/clearWorkspacesSort',
            }),
            manualArchive() {
                // TODO: TBA
            },
            goToHome() {
                this.$emit('homePage');
            },
            expand(item) {
                if(item.isFirst) {
                    this.clearWorkspacesSort();
                    this.$router.push({
                        name: 'archive-workspaces',
                    }).catch(() => {});
                } else {
                    if (item.isWorkspace) {
                        this.selectedWorkspace = item.name;
                        this.doFetchFolders({payload: {offset: 0, pageSize: 100}, id: item.documentLocationRealName})
                        .then(()=> {
                            this.makeFolder(item, this.folders);
                        })
                        this.$router.push({
                            name: 'subfolder',
                            params: {id: item.documentLocationRealName, workspace:item.workspace}
                        }).catch(() => {});
                    }
                    if(item.isFolder) {
                        const workspaceList = this.treeData.children;
                        const selectedWorkspace = workspaceList.find(workspace => workspace.name === this.selectedWorkspace);

                        const folders = this.findParents(selectedWorkspace, item.id);
                        const breadcrumbsList =[this.selectedWorkspace].concat(folders);

                        this.doUpdateBreadcrumbs(breadcrumbsList);
                        this.doFetchFoldersFromNode({
                            payload: {offset: 0, pageSize: 100},
                            parentFolderId: item.id,
                            workspaceRealName: item.workspace.documentLocationRealName
                        })
                        .then(() => {
                            this.clearSort();
                            this.makeFolder(item, this.subfolders)
                            this.$router.replace({
                                name: 'archive-documents',
                                params: {
                                    id: item.id,
                                    folder: item.folder,
                                    workspace: item.workspace
                                }
                            }).catch(() => {});
                        })
                    }
                }
            },
           async makeFolder (item, folders) {
                const children = folders.map(row => {
                    return {
                        isFolder: true,
                        name: row.name,
                        id: row.id,
                        folder: row,
                        workspace: item.workspace,
                        children: []
                    }
                })
                Vue.set(item, 'children', children);
            },
            addItem (item) {
                item.children.push({
                    name: item.name,
                    children: []
                });
            },
            findParents(node, searchForName) {
                if(node.id === searchForName) {
                    return []
                }
                if (Array.isArray(node.children)) {
                    for (var treeNode of node.children) {
                        const childResult = this.findParents(treeNode, searchForName)
                        if (Array.isArray(childResult)) {
                            return [ treeNode.name ].concat( childResult );
                        }
                    }
                }
            },
            goToArchiveApp() {
                window.open(window.VUE_APP_ARCHIVE_URL, '_blank').focus();
            }
        }
    };
</script>

<style lang="scss" scoped>
    #tree-view-section {
        white-space: nowrap;
        overflow: auto;
           max-width: 200px;
    }
    .disable-tree {
        pointer-events: none;
        opacity: 0.6;
    }
</style>
