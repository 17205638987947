import ApiService from '@/shared/services/api.service';

const GET_NOTIFICATIONS_TEMPLATES = 'getNotificationsTemplates';
const GET_NOTIFICATION_TEMPLATE = 'getNotificationTemplate';
const UPDATE_NOTIFICATION_TEMPLATE = 'updateNotificationTemplate';
const UPDATE_SEARCH = 'updateSearch';
const UPDATE_CURRENT_PAGE = 'updateCurrentPage';

const SET_NOTIFICATIONS_TEMPLATES = 'setNotificationsTemplates';
const SET_ROWS = 'setRows';
const SET_SEARCH = 'setSearch';
const SET_CURRENT_PAGE = 'setCurrentPage';

const state = {
    templates: [],
    rows: 0,
    search: '',
    perPage: 10,
    currentPage: 1,
};

const getters = {
    templates(state) {
        return state.templates;
    },
    rows(state) {
        return state.rows;
    },
    perPage(state) {
        return state.perPage;
    },
};

const actions = {
    [GET_NOTIFICATIONS_TEMPLATES](context, params) {
        context.commit('shared/activateLoading', 'notifications/templates/getNotificationsTemplates', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.query('notifications/templates', { params: { ...params, pagination: true } })
                .then(({ data }) => {
                    context.commit(SET_ROWS, parseInt(data.pagination.total));
                    context.commit(SET_NOTIFICATIONS_TEMPLATES, data.data);
                    resolve(data.data);
                })
                .catch(( error ) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'notifications/templates/getNotificationsTemplates', { root: true });
                });
        });
    },
    [GET_NOTIFICATION_TEMPLATE](context, id) {

        return new Promise((resolve, reject) => {
            ApiService.get(`notifications/templates/${id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    reject(error);
                });
        });
    },
    [UPDATE_NOTIFICATION_TEMPLATE](context, payload) {
        context.commit('shared/activateLoading', 'notifications/templates/updateNotificationTemplate', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.patch(`notifications/templates/${payload.id}`, {
                title: payload.title,
                subject: payload.subject,
                message: payload.message,
            })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( error ) => {
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'notifications/templates/updateNotificationTemplate', { root: true });
                });
        });
    },
    [UPDATE_SEARCH](context, search) {
        context.commit(SET_SEARCH, search);
    },
    [UPDATE_CURRENT_PAGE](context, currentPage) {
        context.commit(SET_CURRENT_PAGE, currentPage);
    },
};

const mutations = {
    [SET_NOTIFICATIONS_TEMPLATES](state, templates) {
        state.templates = templates;
    },
    [SET_ROWS](state, rows) {
        state.rows = rows;
    },
    [SET_SEARCH](state, search) {
        state.search = search;
    },
    [SET_CURRENT_PAGE](state, currentPage) {
        state.currentPage = currentPage;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
