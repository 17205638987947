<template>
    <b-card :class="cardClass" :id="id">
        <div :class="headerClass" v-if="showHeader">
            <slot name="header"></slot>
            <div class="st-section-title">
                <span v-if="validate" class="st-section-validation">
                    <template v-if="wasValidated">
                        <i
                            v-if="validation === 'success'"
                            :class="['fas fa-check-circle', 'text-success']"
                        ></i>
                        <i v-else class="fas fa-times-circle text-danger"></i>
                    </template>
                    <i v-else class="fas fa-times-circle text-dark-light"></i>
                </span>
                <span class="st-section-title-text">{{ header }}</span>
            </div>

            <b-button
                v-if="collapsible"
                class="collapsible"
                variant="light"
                v-b-toggle="`${collapsibleId}`"
            >
                <span class="section-closed">
                    <i class="fas fa-chevron-down"></i>
                </span>
                <span class="section-open">
                    <i class="fas fa-chevron-up"></i>
                </span>
            </b-button>
        </div>

        <b-collapse v-if="collapsible" :id="collapsibleId" v-model="visible">
            <div class="st-section-body">
                <slot name="body"></slot>
            </div>
        </b-collapse>
        <div v-else class="st-section-body">
            <slot name="body"></slot>
        </div>
    </b-card>
</template>

<script>
import { BCollapse, VBToggle } from "bootstrap-vue";

export default {
    name: "StSection",
    components: {
        BCollapse,
    },
    directives: {
        "b-toggle": VBToggle,
    },
    props: {
        id: {
            type: String,
        },
        header: String,
        collapsible: {
            type: Boolean,
            default: false,
        },
        collapsibleId: {
            type: String,
            default: "section-body",
        },
        isCollapsed: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: String,
        },
        validate: {
            type: Boolean,
            default: false,
        },
        validation: {
            type: String,
            default: "none", // 'none', 'success', 'error'
        },
        wasValidated: {
            type: Boolean,
            default: false,
        },
        marginTopSize: {
            type: String,
            default: '6',
        },
        showHeader: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            visible: !this.isCollapsed,
        };
    },
    computed: {
        headerClass() {
            let hClass = "st-section-header";
            if (!this.collapsible) {
                hClass += " not-collapsible";
            }

            return hClass;
        },
        cardClass() {
            let cClass = `st-section mt-${this.marginTopSize}`;
            if (this.collapsible) {
                cClass += " st-section-slim";
            }

            if (this.customClass) {
                cClass += " " + this.customClass;
            }

            return cClass;
        },
    },
    watch:{
        visible(data) {
            this.$emit('show', {extended: data, areaId:this.collapsibleId });
        }
    },
    methods: {},
};
</script>
