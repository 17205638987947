import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class DocumentTemplatePermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.documentTemplateRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.documentTemplateCreate,
        );
        this.edit = permissionChecker.match(
            Permissions.values.documentTemplateEdit,
        );
        this.destroy = permissionChecker.match(
            Permissions.values.documentTemplateDestroy,
        );
    }
}
