import routes from './auth-routes';
import store from './auth-store';
import authGuardMixin from './guards/auth-guard-mixin';
import unauthGuardMixin from './guards/unauth-guard-mixin';
import requiresEmailVerificationGuardMixin from './guards/requires-email-verification-guard-mixin';
import requiresPhoneVerificationGuardMixin from './guards/requires-phone-verification-guard-mixin';
import requiresConfirmationKeyGuardMixin from './guards/requires-confirmation-key-guard-mixin';
import permissionGuardMixin from './guards/permission-guard-mixin';
import notRenderInAppGuardMixin from './guards/not-render-in-app-guard-mixin';
import requiresTokenVerificationGuardMixin from './guards/requires-token-verification-guard-mixin';
import profileCompletedGuardMixin from './guards/profile-completed-guard-mixin';


const mixins = [
    authGuardMixin,
    unauthGuardMixin,
    requiresEmailVerificationGuardMixin,
    requiresPhoneVerificationGuardMixin,
    requiresConfirmationKeyGuardMixin,
    requiresTokenVerificationGuardMixin,
    permissionGuardMixin,
    notRenderInAppGuardMixin,
    profileCompletedGuardMixin
];

export default {
  routes,
  store,
  mixins
};
