const Layout = () => import('@/modules/layout/components/layout');
import Permissions from '@/security/permissions';

const Institutions = () => import('@/modules/administrator/pages/OrganisationList.vue');
const InstitutionDetails = () => import('@/modules/administrator/components/institutions/InstitutionDetails.vue');

import { storeAsync } from '../../app-module';
const getAdministratorModule = () => import('@/modules/administrator/administrator-store');
const importAdministratorStore = () =>
    getAdministratorModule().then((storeModule) => {
        if (!storeAsync().hasModule('administrator')) storeAsync().registerModule('administrator', storeModule.default);
        else return;
    });

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'institutions',
        path: '/institutions',
        component: Institutions,
        exact: true,
        meta: {
            auth: true,
            permission: Permissions.values.organisationsRead,
        },
        beforeEnter: (to, from, next) => {
            importAdministratorStore().then(() => {
                next();
            });
        }
      },
      {
        name: 'institution',
        path: '/institution',
        component: InstitutionDetails,
        exact: true,
        meta: {
            auth: true,
            authOrg: true,
            permission: Permissions.values.organisationsView,
        },
        beforeEnter: (to, from, next) => {
            importAdministratorStore().then(() => {
                next();
            });
        }
      },
    ],
  },
];
