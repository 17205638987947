export const isObjectEmpty = (obj) =>
    Object.entries(obj).length === 0 && obj.constructor === Object;

export const strongPassword = () => {
  return {
    validate: (input) => {
      const value = input.value;
      if (value === '') {
        return {
          valid: true,
        };
      }
      const re = /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&\'()*+,-.\/:;<=>?@[\]^_\`{|}~])[\w\d!"#$%&\'()*+,-.\/:;<=>?@[\]^_\`{|}~]{8,}$/;
      if (value.match(re)) {
        return {
          valid: true,
        };
      }
      return {
        valid: false,
      };
    }
  }
};

export const validateEmailDomain = (blacklistedDomains) => {
  return {
    validate: (input) => {
      if (input && !input.value) {
        return {
          valid: true,
        };
      }

      if (!input.value.includes('@')) {
        return {
          valid: true,
        };
      }
      const emailDomain = input.value.split('@')[1];
      if (!emailDomain) {
        return {
          valid: true,
        };
      }
      
      // Check if the domain is in the blacklist
      if (blacklistedDomains.includes(emailDomain)) {
        return {
          valid: false,
        };
      }

      return {
        valid: true,
      };;
    }
  }
}

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const renderInApp = () => {
    const host = window.location.host;
    const parts = host.split('.');
    const subdomain = parts[0];
    const renderInApp = subdomain === 'app';
    return renderInApp;
};

export const bytesConvertor = (bytes, unit, decimalsNumber) => {
    const kb = 1024;
    const mb = 1048576;
    const gb = 1073741824;
    let result ;
    switch (unit.toLowerCase()) {
        case 'kb':
            result = parseFloat((bytes / kb).toFixed(decimalsNumber));
            break;
        case 'mb':
            result = parseFloat((bytes / mb).toFixed(decimalsNumber));
            break;
        case 'gb':
            result = parseFloat((bytes / gb).toFixed(decimalsNumber));
            break;
        default:
            result = parseFloat((bytes / kb).toFixed(decimalsNumber));
    }
    return result;
};

export const generateRandomId = () => {
  return Math.random().toString(36).substr(2, 10);
}

export const camelCaseToDash = (string) => {
  return string.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase())
}

