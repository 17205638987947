import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const ApplicationTypeList = () => import('@/modules/application-type/pages/ApplicationTypeList.vue');

const getApplicationTypeModule = () => import('@/modules/application-type/application-type-store');
const importApplicationTypeStore = () =>
    getApplicationTypeModule().then((storeModule) => {
        if (!storeAsync().hasModule('applicationType')) storeAsync().registerModule('applicationType', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'application-type',
                path: '/application-type',
                component: ApplicationTypeList,
                meta: {
                    auth: true,
                    permission: Permissions.values.applicationTypeRead,
                },
                beforeEnter: (to, from, next) => {
                    importApplicationTypeStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
