const Layout = () => import('@/modules/layout/components/layout');
import { storeAsync } from '@/app-module';

const HomePage = () => import('@/modules/home/components/home-page.vue');
const getApplicationsModule = () => import('@/modules/applications/applications-store');
const importApplicationsStore = () =>
    getApplicationsModule().then(async (storeModule) => {
        if (!storeAsync().hasModule('applications')) await storeAsync().registerModule('applications', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        exact: true,
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'home',
                path: '/home',
                component: HomePage,
                exact: true,
                meta: { auth: true },
                beforeEnter: (to, from, next) => {
                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
