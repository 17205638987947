<template>
    <b-form-datepicker
        v-model="date"
        v-bind="labels[locale] || {}"
        :placeholder="$t('GENERAL.DATEPICKER.SELECT_DATE')"
        :date-format-options="formatOptions"
        :locale="locale"
        :start-weekday="1"
        :name="name"
        @input="onChange"
        :min="min"
        :max="max"
        :disabled="disabled"
        :readonly="readonly"
        :size="size"
        :style="style"
        class="st-datepicker"
    />
</template>

<script>
import i18nService from '@/shared/services/i18n.service';
export default {
    name: 'StDatepicker',
    data() {
        return {
            locale: i18nService.getActiveLanguage(),
            style: {},
            labels :{
                ro: {
                    labelNoDateSelected: this.$t('GENERAL.DATEPICKER.SELECT_DATE'),
                    labelHelp: this.$t('GENERAL.DATEPICKER.HELP')
                }
            }
        }
    },
    props: {
        value: {
            required: true,
            default: '',
        },
        formatOptions: {
            type: Object,
            default: () => ({
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
            })
        },
        name: {
            type: String,
            required: true,
        },
        min: {
            type: Date,
        },
        max: {
            type: Date,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'md',
        }
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    mounted() {
        if (this.size === 'sm') {
            this.style = {
                paddingTop: '2px',
                paddingBottom: '2px'
            }
        }
    },
    methods: {
        onChange() {
            this.$emit('change');
        },
    },
}
</script>
