<template>
    <div class="d-flex align-items-center">
        <div class="pr-4">
            <b-form-datepicker
                v-model="date[startField]"
                v-bind="labels[locale] || {}"
                :placeholder="startPlaceholder"
                :date-format-options="formatOptions"
                :locale="locale"
                :start-weekday="1"
                :max="date.end"
                @input="onChange"
                :dropleft="dropleft"
                :dropright="dropright"
                :dropup="dropup"
                menu-class="st-form-picker"
            />
        </div>
        <div>
            <b-form-datepicker
                v-model="date[endField]"
                v-bind="labels[locale] || {}"
                :placeholder="endPlaceholder"
                :date-format-options="formatOptions"
                :locale="locale"
                :start-weekday="1"
                :min="date.start"
                @input="onChange"
                :dropleft="dropleft"
                :dropright="dropright"
                :dropup="dropup"
                menu-class="st-form-picker"
            />
        </div>
    </div>
</template>

<script>
import i18nService from '@/shared/services/i18n.service';
export default {
    name: 'StDateRange',
    data() {
        return {
            locale: i18nService.getActiveLanguage(),
            labels :{
                ro: {
                    labelNoDateSelected: this.$t('GENERAL.DATEPICKER.SELECT_DATE'),
                    labelHelp: this.$t('GENERAL.DATEPICKER.HELP')
                }
            },
            dropleft: false,
            dropright: false,
            dropup: false
        }
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        startField: {
            type: String,
            required: true,
        },
        endField: {
            type: String,
            required: true,
        },
        formatOptions: {
            type: Object,
            default: () => ({
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
            })
        },
        position: {
            type: String,
            default: 'dropleft'
        }
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        startPlaceholder() {
            return this.$t('GENERAL.DATEPICKER.FROM_DATE');
        },
        endPlaceholder() {
            return this.$t('GENERAL.DATEPICKER.TO_DATE');
        },
    },
    methods: {
       onChange() {
            this.$emit('change');
        },
    },
    mounted() {
        this[this.position] = true;
    }
}
</script>
