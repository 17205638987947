import Vue from 'vue';
import Vuex from 'vuex';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import auth from '@/modules/auth/auth-module';
import shared from '@/shared/shared-module';
import home from '@/modules/home/home-module';
import layout from '@/modules/layout/layout-module';
import notifications from '@/modules/notifications/notifications-module';

const modules = {
    shared,
    home,
    auth,
    layout,
    notifications,
};

const exists = (el) => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
    Object.keys(modules)
        .map((key) => modules[key].components)
        .filter(exists)
        .forEach((components) => {
            components.forEach((component) => {
                Vue.component(component.name, component);
            });
        });

    Object.keys(modules)
        .map((key) => modules[key].directives)
        .filter(exists)
        .forEach((directives) => {
            directives.forEach((directive) => {
                Vue.directive(directive.name, directive.implementation);
            });
        });

    Object.keys(modules)
        .map((key) => modules[key].mixins)
        .filter(exists)
        .forEach((mixins) => {
            mixins.forEach((mixin) => {
                Vue.mixin(mixin);
            });
        });
}

const buildStores = () => {
    const output = {};

    Object.keys(modules)
        .filter((key) => !!modules[key].store)
        .forEach((key) => {
            output[key] = modules[key].store;
        });
    return output;
};

let store = null;

const storeAsync = () => {
    if (!store) {
        store = new Vuex.Store({ modules: buildStores() });
    }

    return store;
};

// FIX for NavigationDuplicated: Avoided redundant navigation to current location
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

const domainRouterFiles = require.context('./modules', true, /routes.js/)
const domainRoutes = domainRouterFiles.keys().reduce((carry, item) => {
  return [...carry, ...domainRouterFiles(item).default]
}, []);

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    ...domainRoutes,
    {
        path: '*',
        redirect: '/404',
    },
];

let router = null;
const routerAsync = () => {
    if (!router) {
        router = new Router({
            mode: 'history',
            routes,
            scrollBehavior() {
                return { x: 0, y: 0 };
            },
        });

        router.beforeEach((to, from, next) => {
            if (to.name) {
                ProgressBar.start();
            }

            next();
        });

        router.afterEach(() => {
            ProgressBar.done();
        });
    }

    return router;
};

export { setupComponentsFiltersDirectivesAndMixins, routerAsync, storeAsync };
