export default {
    name: 'positiveIntegerOnly',
    implementation: {
        bind(el) {
            el.addEventListener('keypress', (event) => {
                // can not start with 0
                // can not contain any character except digits
                if ((el.value === '' && event.charCode === 48)
                    || !(event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57)
                ) {
                    event.preventDefault();
                }
            })
        }
    }
}