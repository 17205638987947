import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const ArchiveDashboard = () => import('@/modules/archive/pages/ArchiveDashboard.vue');
const ArchiveWorkspaces = () => import('@/modules/archive/pages/ArchiveWorkspaces.vue');
const ArchiveSubfolders = () => import('@/modules/archive/pages/ArchiveSubfolders.vue');
const ArchiveDocuments = () => import('@/modules/archive/pages/ArchiveDocuments.vue');
const ArchiveDocument = () => import('@/modules/archive/pages/ArchiveDocument.vue');

const getArchiveModule = () => import('@/modules/archive/archive-store');
const importArchiveStore = () =>
    getArchiveModule().then((storeModule) => {
        if (!storeAsync().hasModule('archive')) storeAsync().registerModule('archive', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'archive-dashboard',
                path: '/archive-dashboard',
                component: ArchiveDashboard,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                },
                beforeEnter: (to, from, next) => {
                    importArchiveStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'archive-workspaces',
                path: '/archive-workspaces',
                component: ArchiveWorkspaces,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                },
                beforeEnter: (to, from, next) => {
                    importArchiveStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'subfolder',
                path: '/subfolder/:id',
                component: ArchiveSubfolders,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importArchiveStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'archive-documents',
                path: '/archive-documents/:id',
                component: ArchiveDocuments,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importArchiveStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'archive-document',
                path: '/archive-document/:id',
                component: ArchiveDocument,
                meta: {
                    auth: true,
                    isArchive: true,
                    permission: Permissions.values.archiveRead,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importArchiveStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
