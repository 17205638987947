class Roles {
    static get values() {
        return {
            super_admin: 'super_admin',
            admin: 'admin',
            staff: 'staff',
            citizen: 'citizen',
            foreigner: 'foreigner',
            legal_entity: 'legal_entity',
        }
    }
    static get staffRoles() {
        return [
            this.values.super_admin,
            this.values.admin,
            this.values.staff,
        ]
    }
}

export default Roles;
