import { storeAsync } from '../../../app-module';

export const notificationTemplateResolver = async (to, from, next) => {
    const id = to.params.id;
    const store = storeAsync();

    try {
        to.meta['data'] = await store.dispatch('notifications/templates/getNotificationTemplate', id);
        next();
    } catch(err) {
        next({ path: '/404' });
    }
}