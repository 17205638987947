import PermissionChecker from '@/security/permission-checker';
import Permissions from '@/security/permissions';

export class InternalDocumentsPermissions {
    constructor(currentUser) {
        const permissionChecker = new PermissionChecker(
            currentUser,
        );

        this.read = permissionChecker.match(
            Permissions.values.internalDocumentsRead,
        );
        this.create = permissionChecker.match(
            Permissions.values.internalDocumentsCreate,
        );
    }
}
