import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const ControlList = () => import('@/modules/control/pages/ControlList.vue');
const ControlForm = () => import('@/modules/control/pages/ControlForm.vue');

const getControlModule = () => import('@/modules/control/control-store');
const importControlStore = () =>
    getControlModule().then((storeModule) => {
        if (!storeAsync().hasModule('control')) storeAsync().registerModule('control', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'controls',
                path: '/controls',
                component: ControlList,
                meta: {
                    auth: true,
                    permission: Permissions.values.controlRead,
                },
                beforeEnter: (to, from, next) => {
                    importControlStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'controlNew',
                path: '/control/new',
                component: ControlForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.controlRead,
                },
                beforeEnter: (to, from, next) => {
                    importControlStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'controlEdit',
                path: '/control/:id',
                component: ControlForm,
                meta: {
                    auth: true,
                    permission: Permissions.values.controlEdit,
                },
                props: true,
                beforeEnter: (to, from, next) => {
                    importControlStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
