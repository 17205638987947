import { storeAsync } from '@/app-module';

export default {
    name: 'positiveDecimalOnly',
    implementation: {
        bind(el) {
            el.addEventListener('keypress', (e) => {

                const separator = storeAsync().getters['shared/appConfig'].DECIMAL_SEPARATOR;
               
                const keyCode = (e.keyCode ? e.keyCode : e.which);
                const key = e.key;
                const value = el.value;
                
                const i = value.indexOf(separator);
    
                if ((keyCode < 48 || keyCode > 57) && (key !== separator || i !== -1)) {
                    // allows only digits and one separator
                    e.preventDefault();
                } 
                
                if (value.split(separator)[1]?.length > 1) {
                    // allows only 2 decimals
                    e.preventDefault();
                }
            });
        }
    }
}