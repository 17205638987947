import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const PaymentRegistryList = () => import('@/modules/payment-registry/pages/PaymentRegistryList.vue');
const TaxCalculator = () => import('@/modules/payment-registry/pages/TaxCalculator.vue');
const PaymentOrder = () => import('@/modules/payment-registry/pages/PaymentOrder.vue');


const getPaymentRegistryModule = () => import('@/modules/payment-registry/payment-registry-store');
const importPaymentRegistryStore = () =>
    getPaymentRegistryModule().then((storeModule) => {
        if (!storeAsync().hasModule('paymentRegistry')) storeAsync().registerModule('paymentRegistry', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'paymentRegistry',
                path: '/payments',
                component: PaymentRegistryList,
                meta: {
                    auth: true,
                    permission: Permissions.values.paymentRegistryRead,
                },
                beforeEnter: (to, from, next) => {
                    importPaymentRegistryStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'taxCalculator',
                path: '/tax-calculator',
                component: TaxCalculator,
                meta: {
                    auth: true,
                    permission: Permissions.values.taxCalculatorView,
                },
                beforeEnter: (to, from, next) => {
                    importPaymentRegistryStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'paymentOrder',
                path: '/payment-order',
                component: PaymentOrder,
                meta: {
                    auth: true,
                    permission: Permissions.values.paymentOrderCreate,
                },
                beforeEnter: (to, from, next) => {
                    importPaymentRegistryStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
