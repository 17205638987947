const Layout = () => import('@/modules/layout/components/layout');
const Profile = () => import('@/modules/profile/pages/Profile.vue');
const StaffEnrollmentRedirect = () => import('@/modules/profile/pages/StaffEnrollmentRedirect.vue');
import { storeAsync } from '../../app-module';

const getProfileModule = () => import('@/modules/profile/profile-store');
const importProfileStore = () =>
getProfileModule().then((storeModule) => {
        if (!storeAsync().hasModule('profile')) storeAsync().registerModule('profile', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        exact: true,
        component: Layout,
        children: [
            {
                name: 'profile',
                path: '/profile',
                component: Profile,
                exact: true,
                meta: {auth: true},
                beforeEnter: (to, from, next) => {
                    importProfileStore().then(() => {
                        next();
                    });
                },
            },
            {
                name: 'staff-enrollment-redirect',
                path: '/staff-enrollment/redirect/:id',
                component: StaffEnrollmentRedirect,
                exact: true,
                meta: {auth: true},
                beforeEnter: (to, from, next) => {
                    importProfileStore().then(() => {
                        next();
                    });
                },
            }
        ],
    },
];
