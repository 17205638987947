const MENU_KEY = 'st_menu';

export const getMenuStatus = () => window.localStorage.getItem(MENU_KEY);

export const saveMenuStatus = (status) => {
  window.localStorage.setItem(MENU_KEY, status);
};

export const destroyMenuStatus = () => {
  window.localStorage.removeItem(MENU_KEY);
};

export default { getMenuStatus, saveMenuStatus, destroyMenuStatus };
