import Vue from 'vue';
import VueI18n from 'vue-i18n';
import i18nService from '@/shared/services/i18n.service.js';

// Localisation language list
import { locale as ro } from '@/config/i18n/ro';
// import { locale as en } from '@/config/i18n/en';
// import { locale as hu } from '@/config/i18n/hu';

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, ro};

// get current selected language
const lang = i18nService.getActiveLanguage() || 'ro';

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: lang, // set locale
    messages, // set locale messages
    // Key - language to use the rule for, `'ro'`, in this case
    // Value - function to choose right plural form
    pluralizationRules: {
        /**
         * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
         * @param choicesLength {number} an overall amount of available choices
         * @returns a final choice index to select plural word by
         */
        'ro': function (choice, choicesLength) {
            if (choice === 0) {
                return 0;
            }
            if (choice === 1) {
                return 1;
            }
            return 2;
        }
    }
});

export default i18n;
