import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';

const DecisionTargetList = () => import('@/modules/decision-target/pages/DecisionTargetList.vue');

import { storeAsync } from '../../app-module';
const getDecisionTargetModule = () => import('@/modules/decision-target/decision-target-store');
const importDecisionTargetStore = () =>
    getDecisionTargetModule().then((storeModule) => {
        if (!storeAsync().hasModule('decisionTarget')) storeAsync().registerModule('decisionTarget', storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'decision-target-list',
                path: '/decision-target-list',
                component: DecisionTargetList,
                meta: {
                    auth: true,
                    permission: Permissions.values.decisionTargetRead,
                },
                beforeEnter: (to, from, next) => {
                    importDecisionTargetStore().then(() => {
                        next();
                    });
                }
            },
        ],
    },
];
