<template>
    <b-button
      v-if="!notRendered"
      :id="id"
      :class="buttonStyleClasses"
      :variant="variantComputed"
      :size="sizeComputed"
      :active="active"
      :disabled="disabled"
      :block="block"
      :to="to"
      :aria-label="ariaLabel"
      :aria-labelledby="ariaLabelledBy"
      :aria-describedby="ariaDescribedBy"
      @click="clickCallback">
      <slot></slot>
    </b-button>
</template>

<script>
import { isObjectEmpty } from '../../core/helpers/globalMethods';

const VARIANTS = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'info',
  'light',
  'dark',
  'link',
  'hover',
  'custom-link'
];
const SIZES = {
  'small': 'sm',
  'medium': '',
  'large': 'lg',
};

export default {
  name: 'StButton',
  props: {
    notRendered: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'medium',
    },
    block: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Set a custom css class to the button
    customClass: String,
    // Defines a callback function which can be used to update parent state on change event
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction: String,
    // Defines params for callback function or callback action
    callbackParams: {
      type: Object,
      default: () => ({}),
    },
    title: {
        type: String,
        default: 'primary',
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    to: String,
  },
  computed: {
    buttonStyleClasses() {
      const classes = [];
      if (this.customClass) {
        classes.push(this.customClass);
      }
      if (this.variant === 'link') {
          classes.push('st-btn-custom-link');
      }
      if (this.spinner) {
        classes.push('spinner spinner-right');
      }
      return classes;
    },
    variantComputed() {
      const variant = VARIANTS.includes(this.variant) ? this.variant : 'primary';
      if (this.outline) {
        return `outline-${variant}`;
      }
      return variant;
    },
    sizeComputed() {
      const size = Object.keys(SIZES).includes(this.size) ? this.size : 'default';
      return SIZES[size];
    },
  },
  methods: {
    clickCallback() {
      if (this.spinner) {
        return;
      }

      if (this.callback) {
        if (!isObjectEmpty(this.callbackParams)) {
          this.callback(this.callbackParams);
        } else {
          this.callback();
        }

        if (this.callbackAction) {
          this.$store.dispatch(this.callbackAction, this.callbackParams);
        }
      }
    }
  },
};
</script>
