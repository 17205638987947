import routes from '@/shared/shared-routes';

import Loader from '@/shared/components/StLoader';
import StButton from '@/shared/components/StButton';
import StInputText from '@/shared/components/StInputText';

import ariaCommon from '@/shared/mixins/aria-common';
import positiveIntegerOnly from '@/shared/directives/positive-integer-only';
import positiveDecimalOnly from '@/shared/directives/positive-decimal-only';
import can from '@/shared/directives/can';

import store from '@/shared/services/store/shared-store';

export default {
    components: [
        Loader,
        StButton,
        StInputText,
    ],
    filters: [],
    mixins: [ ariaCommon ],
    directives: [
        positiveIntegerOnly,
        positiveDecimalOnly,
        can,
    ],
    routes,
    store
};
